<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <div class="content-container">
      <div class="mb30 todo-input" style="grid-template-columns: 1fr 3fr">
        <mat-form-field appearance="fill">
          <mat-label>SKU</mat-label>
          <input matInput disabled [(ngModel)]="sku" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Modell</mat-label>
          <input matInput [(ngModel)]="model" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="mb30 todo-input" style="grid-template-columns: 1fr 1fr">
        <mat-form-field appearance="fill">
          <mat-label>RTG</mat-label>
          <input matInput type="number" [(ngModel)]="rtg" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Disp</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="available"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="mb30 todo-input" style="grid-template-columns: 1fr">
        <mat-form-field appearance="fill">
          <mat-label>EAN</mat-label>
          <input matInput type="string" [(ngModel)]="ean" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-checkbox [(ngModel)]="premium">Premium</mat-checkbox>
      <div
        style="display: flex; justify-content: flex-end"
        *ngIf="userService.User | async as User"
      >
        <button
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          *ngIf="User.HasPermission(Permissions.EDIT_TV)"
        >
          Spara SKU
        </button>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
