import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { unix } from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  comments$: Observable<any>;
  userId$: Observable<any>;
  userId: string;

  comment: string;

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    const todoId = this.data.item.ref.parent.parent.id;

    this.comments$ = this.db.doc(`todo/${todoId}/items/${this.data.item.ref.id}`).snapshotChanges().pipe(map((itemSnapshot: any) => {
      const item = itemSnapshot.payload.data();
      if (item.comments === undefined) {
        item.comments = [];
      }
      return item.comments.sort((a, b) => {
        if (a.timestamp.seconds < b.timestamp.seconds) {
          return 1;
        }
        if (a.timestamp.seconds > b.timestamp.seconds) {
          return -1;
        }
        return 0;
      });
    }));

    this.userId$ = this.afAuth.user.pipe(take(1), map((user: any) => {
      this.userId = user.uid;
      return user.uid;
    }));
  }

  getTime(comment) {
    return unix(comment.timestamp.seconds).format('YYYY-MM-DD HH:mm');
  }

  async addComment() {

    if (typeof this.comment === 'string' && this.comment.length > 0) {
      const uid = this.afAuth.auth.currentUser.uid;
      const userDoc = await this.db.doc(`users/${uid}`).get().toPromise();
      const user = userDoc.data();
      user.ref = userDoc.ref;
      user.id = userDoc.id;
      const c = {
        comment: this.comment,
        timestamp: firestore.Timestamp.now(),
        commentBy: {
          displayName: user.displayName,
          ref: user.ref
        }
      };
      this.data.item.ref.update({
        comments: firestore.FieldValue.arrayUnion(c)
      });
      this.comment = '';
    }

  }

  close() {
    this.dialogRef.close();
  }

}
