<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <app-loading-dialog
      [loadingState]="loadingState"
      [messages]="messages"
    ></app-loading-dialog>
    <div class="content-container">
      <mat-form-field>
        <mat-label>Användare</mat-label>
        <mat-select
          (selectionChange)="onUserChanged($event)"
          [(value)]="selectedUser"
        >
          <mat-option *ngFor="let user of users" [value]="user">
            {{ user.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="selectedUser">
        <mat-form-field appearance="fill">
          <mat-label>Namn</mat-label>
          <input matInput [(ngModel)]="displayName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>E-postadress</mat-label>
          <input matInput [(ngModel)]="email" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Lösenord</mat-label>
          <input matInput [(ngModel)]="password" autocomplete="off" />
        </mat-form-field>
        <div style="display: grid; grid-gap: 10px">
          <p>Todo</p>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.CREATE_TODO)"
            checked="{{ HasPermission(Permissions.CREATE_TODO) }}"
          >
            Skapa
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.EDIT_TODO)"
            checked="{{ HasPermission(Permissions.EDIT_TODO) }}"
          >
            Redigera
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.DELETE_TODO)"
            checked="{{ HasPermission(Permissions.DELETE_TODO) }}"
          >
            Ta bort
          </mat-slide-toggle>
          <p>TV</p>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.EDIT_TV)"
            checked="{{ HasPermission(Permissions.EDIT_TV) }}"
          >
            Redigera
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.IMPORT_TV)"
            checked="{{ HasPermission(Permissions.IMPORT_TV) }}"
          >
            Importera
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.IMPORT_EAN)"
            checked="{{ HasPermission(Permissions.IMPORT_EAN) }}"
          >
            Importera EAN
          </mat-slide-toggle>
          <p>Administration</p>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.MANAGE_SELF)"
            checked="{{ HasPermission(Permissions.MANAGE_SELF) }}"
          >
            Sig själv
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.MANAGE_USERS)"
            checked="{{ HasPermission(Permissions.MANAGE_USERS) }}"
          >
            Användare
          </mat-slide-toggle>
          <p>Sidor</p>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.MANAGE_STATS)"
            checked="{{ HasPermission(Permissions.MANAGE_STATS) }}"
          >
            Statistik
          </mat-slide-toggle>
          <mat-slide-toggle
            (change)="onPermissionChange($event, Permissions.MANAGE_TONERS)"
            checked="{{ HasPermission(Permissions.MANAGE_TONERS) }}"
          >
            Toners
          </mat-slide-toggle>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <button mat-raised-button color="primary" (click)="submit()">
            Spara ändringar
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
