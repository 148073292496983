import { auth, firestore } from "firebase";

export enum StatType {
    TV_ADD,
    TV_SUB
}

export const LogStat = (type: StatType) => {
    const stat = {
        timestamp: firestore.Timestamp.now(),
        type,
        uid: auth().currentUser.uid,
        userRef: firestore().collection('users').doc(auth().currentUser.uid)
    };
    firestore()
        .collection('statistics')
        .doc()
        .set(stat);
};
