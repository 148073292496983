<div (click)="onClick()" class="cell">
  <p style="font-weight: bold; text-align: center">{{ product }}</p>
  <span>{{ label }}</span>
</div>

<div [hidden]="!modalVisible">
  <div class="overlay-wrapper">
    <div class="overlay-content">
      <mat-card>
        <div style="padding: 20px">
          <mat-form-field appearance="fill">
            <mat-label>Produkt</mat-label>
            <input
              #productInputRef
              autocomplete="off"
              [(ngModel)]="productInput"
              matInput
              type="text"
              autofocus
            />
          </mat-form-field>

          <div
            style="
              display: grid;
              grid-template-columns: 100px auto 100px 100px;
              grid-gap: 20px;
            "
          >
            <button mat-raised-button color="warn" (click)="delete()">
              Ta bort
            </button>
            <div></div>
            <button mat-flat-button color="accent" (click)="dismiss()">
              Avbryt
            </button>

            <button mat-raised-button color="primary" (click)="save()">
              Spara
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
