<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        [sidenav]="sidenav"
        showBack="true"
      ></app-header-toolbar>
    </div>
    <div class="content-container">
      <h1 style="display: flex; align-items: center">
        <span style="flex: 1">Hantera TV</span>
        <div *ngIf="userService.User | async as User">
          <button
            *ngIf="User.HasPermission(Permissions.INVENTORY_RTG)"
            style="margin-right: 10px"
            mat-raised-button
            color="primary"
            [routerLink]="['/tv/inventory']"
          >
            Inventera RTG
          </button>
          <button
            *ngIf="User.HasPermission(Permissions.IMPORT_TV)"
            [routerLink]="['/tv/import']"
            mat-icon-button
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </h1>
      <mat-form-field appearance="fill">
        <mat-label>Sök efter SKU eller modell...</mat-label>
        <input autocomplete="off" [formControl]="term" matInput type="text" />
      </mat-form-field>
      <div class="mb30">
        <mat-checkbox [formControl]="premium">Premium</mat-checkbox>
      </div>
      <mat-card class="mat-elevation-z2 mb30">
        <table mat-table [dataSource]="filteredTvs$" style="width: 100%">
          <!-- <table          mat-table          [dataSource]="tv$ | TVSearchFilter: term:premium"          style="width: 100%;"        > -->
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef>SKU</th>
            <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modell</th>
            <td mat-cell *matCellDef="let item">{{ item.model }}</td>
          </ng-container>
          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef>RTG/Disp</th>
            <td mat-cell *matCellDef="let item">
              {{ item.stock.rtg }}/{{ item.stock.available }}
            </td>
          </ng-container>
          <ng-container matColumnDef="hit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <span
                [ngClass]="{
                  error: item.hit <= 10,
                  warning: item.hit > 10 && item.hit < 30,
                  success: item.hit >= 30
                }"
              >
                {{ item.hit }}%
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <span *ngIf="item.premium">P</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 1px"></th>
            <td mat-cell *matCellDef="let item">
              <div
                style="display: grid; grid-gap: 10px; grid-auto-flow: column"
              >
                <div *ngIf="userService.User | async as User">
                  <button
                    *ngIf="User.HasPermission(Permissions.EDIT_TV)"
                    color="accent"
                    mat-mini-fab
                    [routerLink]="['/sku/edit/', item.sku]"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card>
    </div>
    <!-- <div class="fab">  <button mat-fab (click)="createNewTodo()"><mat-icon>add</mat-icon></button></div> -->
  </mat-sidenav-content></mat-sidenav-container
>
