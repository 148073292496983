<mat-sidenav-container *ngIf="userService.User | async as User">
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
        <li *ngIf="User.HasPermission(permissions.MANAGE_STATS)">
          <a [routerLink]="['/stats']">Statistik</a>
        </li>
        <li *ngIf="User.HasPermission(permissions.MANAGE_TONERS)">
          <a [routerLink]="['/toners']">Toners</a>
        </li>
        <!-- <li *ngIf="User.HasPermission(permissions.SERVICE)">
          <a [routerLink]="['/services']">Service</a>
        </li> -->
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar [sidenav]="sidenav"></app-header-toolbar>
    </div>
    <div class="content-container" style="max-width: unset">
      <mat-form-field appearance="fill">
        <mat-label>Sök efter SKU...</mat-label>
        <input
          autocomplete="off"
          [(ngModel)]="searchTerm"
          (keyup)="search()"
          matInput
          type="text"
        />
      </mat-form-field>

      <mat-card>
        <div
          style="
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              text-orientation: upright;
              writing-mode: vertical-rl;
              padding: 0 10px;
              font-size: 18px;
              font-weight: bold;
              grid-area: 1 / 1 / 3 / 2;
            "
          >
            KONTOR
          </div>
          <div
            style="
              grid-area: 1 / 2 / 2 / 3;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 0;
              font-size: 18px;
              font-weight: bold;
            "
          >
            TVÄTT & TORK
          </div>
          <div class="location-grid grid-five" style="grid-area: 2 / 2 / 3 / 3">
            <app-warehouse-location
              locationLabel="A"
              [matrix]="matrixAtoE"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="B"
              [matrix]="matrixAtoE"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="C"
              [matrix]="matrixAtoE"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="D"
              [matrix]="matrixAtoE"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="E"
              [matrix]="matrixAtoE"
            ></app-warehouse-location>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              text-orientation: upright;
              writing-mode: vertical-rl;
              padding: 0 10px;
              font-size: 18px;
              font-weight: bold;
              grid-area: 1 / 3 / 3 / 4;
            "
          >
            MERCH
          </div>
        </div>
      </mat-card>

      <mat-card class="mt30">
        <div
          style="
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              text-orientation: upright;
              writing-mode: vertical-rl;
              padding: 0 10px;
              font-size: 18px;
              font-weight: bold;
              grid-area: 1 / 1 / 3 / 2;
            "
          >
            MERCH
          </div>
          <div
            style="
              grid-area: 1 / 2 / 2 / 3;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 0;
              font-size: 18px;
              font-weight: bold;
            "
          >
            KYL & FRYS & DISK
          </div>
          <div class="location-grid grid-five" style="grid-area: 2 / 2 / 3 / 3">
            <app-warehouse-location
              locationLabel="F"
              [matrix]="matrixFtoH"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="G"
              [matrix]="matrixFtoH"
            ></app-warehouse-location>
            <app-warehouse-location
              locationLabel="H"
              [matrix]="matrixFtoH"
            ></app-warehouse-location>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              text-orientation: upright;
              writing-mode: vertical-rl;
              padding: 0 10px;
              font-size: 18px;
              font-weight: bold;
              grid-area: 1 / 3 / 3 / 4;
            "
          >
            LAGERPORT
          </div>
        </div>
      </mat-card>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
