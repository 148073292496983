<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        [sidenav]="sidenav"
        showBack="true"
      ></app-header-toolbar>
    </div>
    <div class="loading-container" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="content-container">
      <div class="mb30 todo-input">
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <input matInput [(ngModel)]="title" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Deadline</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="deadline.value"
            autocomplete="off"
            disabled
            (dateChange)="onDeadlineChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mb30 todo-item-input">
        <mat-form-field appearance="fill">
          <mat-label>SKU</mat-label>
          <input
            disabled="{{ isEditing() }}"
            matInput
            [(ngModel)]="sku"
            (blur)="searchSKU()"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Modell</mat-label>
          <input matInput [(ngModel)]="model" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Antal</mat-label>
          <input matInput [(ngModel)]="goalAmount" autocomplete="off" />
        </mat-form-field>
        <div class="todo-item-input-actions">
          <button color="primary" mat-mini-fab (click)="onAdd()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="activeSKU" class="mb30">
        <div>
          <span>Premium: </span>
          <small>{{ activeSKU.premium ? "Ja" : "Nej" }}</small>
        </div>
        <div *ngIf="!!activeSKU.stock">
          <span>Lager: </span>
          <small
            >{{ activeSKU.stock.rtg }}/{{ activeSKU.stock.available }}
            <span
              [ngClass]="{
                error:
                  (activeSKU.stock.rtg / activeSKU.stock.available | Hitrate) <=
                  10,
                warning:
                  (activeSKU.stock.rtg / activeSKU.stock.available | Hitrate) >
                    10 &&
                  (activeSKU.stock.rtg / activeSKU.stock.available | Hitrate) <
                    30,
                success:
                  (activeSKU.stock.rtg / activeSKU.stock.available | Hitrate) >=
                  30
              }"
            >
              {{ activeSKU.stock.rtg / activeSKU.stock.available | Hitrate }}%
            </span></small
          >
        </div>
      </div>
      <mat-card class="mat-elevation-z2">
        <table mat-table [dataSource]="items" style="width: 100%">
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef>SKU</th>
            <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            <td mat-footer-cell *matFooterCellDef>Totalt</td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modell</th>
            <td mat-cell *matCellDef="let item">{{ item.model }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="goalAmount">
            <th mat-header-cell *matHeaderCellDef>Antal</th>
            <td mat-cell *matCellDef="let item">
              <div style="display: flex; align-items: center">
                <span>{{ item.goalAmount }}</span>
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                  "
                >
                  <span *ngIf="item.newMeta">
                    <small>
                      ({{ item.newMeta.stock.rtg }}/{{
                        item.newMeta.stock.available
                      }})
                      <span
                        [ngClass]="{
                          error:
                            (item.newMeta.stock.rtg /
                              item.newMeta.stock.available | Hitrate) <= 10,
                          warning:
                            (item.newMeta.stock.rtg /
                              item.newMeta.stock.available | Hitrate) > 10 &&
                            (item.newMeta.stock.rtg /
                              item.newMeta.stock.available | Hitrate) < 30,
                          success:
                            (item.newMeta.stock.rtg /
                              item.newMeta.stock.available | Hitrate) >= 30
                        }"
                      >
                        {{
                          item.newMeta.stock.rtg / item.newMeta.stock.available
                            | Hitrate
                        }}%
                      </span>
                    </small>
                  </span>
                  <span *ngIf="item.meta">
                    <small>
                      ({{ item.meta.stock.rtg }}/{{
                        item.meta.stock.available
                      }})
                      <span
                        [ngClass]="{
                          error:
                            (item.meta.stock.rtg / item.meta.stock.available
                              | Hitrate) <= 10,
                          warning:
                            (item.meta.stock.rtg / item.meta.stock.available
                              | Hitrate) > 10 &&
                            (item.meta.stock.rtg / item.meta.stock.available
                              | Hitrate) < 30,
                          success:
                            (item.meta.stock.rtg / item.meta.stock.available
                              | Hitrate) >= 30
                        }"
                      >
                        {{
                          item.meta.stock.rtg / item.meta.stock.available
                            | Hitrate
                        }}%
                      </span>
                    </small>
                  </span>
                </span>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>{{ getTotalTodo() }}</td>
          </ng-container>
          <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <span *ngIf="item.meta.premium">P</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 1px"></th>
            <td mat-cell *matCellDef="let item">
              <div
                style="display: grid; grid-gap: 10px; grid-auto-flow: column"
              >
                <button color="accent" mat-mini-fab (click)="onEdit(item)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button color="warn" mat-mini-fab (click)="removeItem(item)">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
      </mat-card>
      <div class="mt30 mb30" style="display: flex; justify-content: flex-end">
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Skapa TODO-lista
        </button>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
