<div>
  <!-- <div style="display: grid; grid-template-columns: repeat(4, 1fr)">
    <div *ngFor="let column of numSequence(4)">
      <div *ngFor="let row of numSequence(6)">
        {{ locationLabel }}{{ row }}{{ column }}
      </div>
    </div>
  </div> -->

  <div style="display: grid; grid-template-columns: repeat(4, 100px)">
    <div *ngFor="let column of matrix">
      <div *ngFor="let row of column">
        <div *ngIf="row > 0">
          <app-warehouse-position
            label="{{ locationLabel }}{{ row }}"
            (deleteEvent)="onDelete($event)"
          ></app-warehouse-position>
        </div>
        <div class="divider" *ngIf="row == 0"></div>
      </div>
    </div>
  </div>

  <!-- <div style="display: grid; grid-template-columns: repeat(4, 1fr)">
    <div>
      <div>{{ locationLabel }}11</div>
      <div>{{ locationLabel }}21</div>
      <div>{{ locationLabel }}31</div>
    </div>

    <div>
      <div>{{ locationLabel }}12</div>
      <div>{{ locationLabel }}22</div>
      <div>{{ locationLabel }}32</div>
    </div>

    <div>
      <div>{{ locationLabel }}13</div>
      <div>{{ locationLabel }}23</div>
      <div>{{ locationLabel }}33</div>
    </div>

    <div>
      <div>{{ locationLabel }}14</div>
      <div>{{ locationLabel }}24</div>
      <div>{{ locationLabel }}34</div>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: repeat(4, 1fr)">
    <div>
      <div>{{ locationLabel }}41</div>
      <div>{{ locationLabel }}51</div>
    </div>

    <div>
      <div>{{ locationLabel }}42</div>
      <div>{{ locationLabel }}52</div>
    </div>

    <div>
      <div>{{ locationLabel }}43</div>
      <div>{{ locationLabel }}53</div>
    </div>

    <div>
      <div>{{ locationLabel }}44</div>
      <div>{{ locationLabel }}54</div>
    </div>
  </div> -->
</div>
