<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        [sidenav]="sidenav"
        showBack="true"
      ></app-header-toolbar>
    </div>
    <app-loading-dialog
      [loadingState]="loadingState"
      [messages]="messages"
    ></app-loading-dialog>
    <div class="content-container" *ngIf="loaded">
      <div class="mb10 todo-input" style="grid-template-columns: 3fr 1fr">
        <mat-form-field appearance="fill">
          <mat-label>EAN</mat-label>
          <input
            matInput
            [(ngModel)]="ean"
            autocomplete="off"
            (keyup)="onSubmitEAN($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Antal</mat-label>
          <input
            matInput
            [(ngModel)]="amount"
            autocomplete="off"
            (keyup)="onSubmitAmount($event)"
          />
        </mat-form-field>
      </div>
      <div class="mb30">
        <span>{{ itemDescription }}</span>
      </div>
      <mat-card class="mat-elevation-z2 mb30">
        <table mat-table [dataSource]="counted" style="width: 100%">
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef>SKU</th>
            <td
              mat-cell
              *matCellDef="let item"
              style="cursor: pointer"
              (click)="selectSKU(item)"
            >
              {{ item.sku }}
            </td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modell</th>
            <td
              mat-cell
              *matCellDef="let item"
              style="cursor: pointer"
              (click)="selectSKU(item)"
            >
              {{ item.model }}
            </td>
          </ng-container>
          <ng-container matColumnDef="rtg">
            <th mat-header-cell *matHeaderCellDef>RTG</th>
            <td
              mat-cell
              *matCellDef="let item"
              style="cursor: pointer"
              (click)="selectSKU(item)"
            >
              {{ item.stock.rtg }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card>
      <div style="display: flex; justify-content: flex-end">
        <button mat-raised-button color="primary" (click)="submit()">
          Slutför inventering
        </button>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
