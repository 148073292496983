<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <div class="content-container">
      <div class="mb30 todo-input">
        <mat-form-field appearance="fill">
          <mat-label>Titel</mat-label>
          <input matInput [(ngModel)]="title" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="deadline">
          <mat-label>Deadline</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="deadline.value"
            autocomplete="off"
            disabled
            (dateChange)="onDeadlineChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mb30 todo-item-input">
        <mat-form-field appearance="fill">
          <mat-label>SKU</mat-label>
          <input
            disabled="{{ isEditing() }}"
            matInput
            [(ngModel)]="sku"
            (blur)="searchSKU()"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Modell</mat-label>
          <input matInput [(ngModel)]="model" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Antal</mat-label>
          <input matInput [(ngModel)]="goalAmount" autocomplete="off" />
        </mat-form-field>
        <div class="todo-item-input-actions">
          <button
            color="primary"
            mat-mini-fab
            (click)="!isEditing() ? onAdd() : saveChanges()"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            style="margin-left: 10px"
            color="primary"
            mat-mini-fab
            (click)="clear()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <!-- <div *ngIf="activeSKU as sku" class="mb30">    <div>      <span (click)="log(sku)">Premium: </span>      <small>{{ sku.premium }}</small>    </div>  </div> -->
      <mat-card class="mat-elevation-z2">
        <table mat-table [dataSource]="todoItems$" style="width: 100%">
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef>SKU</th>
            <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            <td mat-footer-cell *matFooterCellDef>Totalt</td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modell</th>
            <td mat-cell *matCellDef="let item">{{ item.model }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="goalAmount">
            <th mat-header-cell *matHeaderCellDef>Antal</th>
            <td mat-cell *matCellDef="let item">
              {{ item.doneAmount }}/{{ item.goalAmount }}
              <small *ngIf="item.realSku | async as sku">
                <span *ngIf="sku.stock">
                  ({{ sku.stock.rtg }}/{{ sku.stock.available }})
                  <span
                    [ngClass]="{
                      error:
                        (sku.stock.rtg / sku.stock.available | Hitrate) <= 10,
                      warning:
                        (sku.stock.rtg / sku.stock.available | Hitrate) > 10 &&
                        (sku.stock.rtg / sku.stock.available | Hitrate) < 30,
                      success:
                        (sku.stock.rtg / sku.stock.available | Hitrate) >= 30
                    }"
                  >
                    {{ sku.stock.rtg / sku.stock.available | Hitrate }}%
                  </span>
                </span>
              </small>
            </td>
            <td mat-footer-cell *matFooterCellDef>{{ getTotalTodo() }}</td>
          </ng-container>
          <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <span *ngIf="item.realSku | async as sku">
                <span *ngIf="sku.premium">P</span>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 1px"></th>
            <td mat-cell *matCellDef="let item">
              <div
                style="display: grid; grid-gap: 10px; grid-auto-flow: column"
              >
                <button color="primary" mat-mini-fab (click)="onEdit(item)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button color="warn" mat-mini-fab (click)="removeItem(item)">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
      </mat-card>
      <div
        *ngIf="userService.User | async as User"
        class="mt30 mb30"
        style="display: flex; justify-content: flex-end"
      >
        <button
          mat-raised-button
          color="warn"
          (click)="onDelete()"
          *ngIf="User.HasPermission(Permissions.DELETE_TODO)"
        >
          Ta bort TODO-lista
        </button>
        <button
          style="margin-left: 20px"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          *ngIf="User.HasPermission(Permissions.EDIT_TODO)"
        >
          Spara TODO-lista
        </button>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
