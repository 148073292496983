import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Hitrate'
})
export class HitratePipe implements PipeTransform {

  transform(value) {
    if (isNaN(value)) {
      return 0;
    }
    return Math.round(value * 100);
  }

}
