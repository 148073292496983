
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { MatIconModule } from '@angular/material/icon';




import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinner, MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModel, ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AuthGuard } from './app-routing.module';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { SignoutComponent } from './signout/signout.component';
import { TodoListingComponent } from './todo/listing/todo-listing.component';
import { TodoComponent } from './todo/todo/todo.component';
import { HitratePipe } from './Hitrate.pipe';
import { CommentDialogComponent } from './todo/todo/comment-dialog/comment-dialog.component';
import { NewTodoComponent } from './todo/new/new-todo/new-todo.component';
import { EditTodoComponent } from './todo/edit-todo/edit-todo.component';
import { TvListingComponent } from './tv/tv-listing/tv-listing.component';
import { EditSkuComponent } from './edit-sku/edit-sku.component';
import { TvInventoryComponent } from './tv/tv-inventory/tv-inventory.component';
import { TvImportComponent } from './tv/tv-import/tv-import.component';
import { StatsComponent } from './stats/stats.component';
import { ProgressRingComponent } from './progress-ring/progress-ring.component';
import { TonersComponent } from './toners/toners.component';
import { ToolsComponent } from './tools/tools.component';
import { E403Component } from './errors/e403/e403.component';
import { ManageSelfComponent } from './manage-self/manage-self.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { WarehouseLocationComponent } from './warehouse/warehouse-location/warehouse-location.component';
import { WarehousePositionComponent } from './warehouse/warehouse-position/warehouse-position.component';
import { HeadCountComponent } from './head-count/head-count.component';
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';

// import { BunkerComponent } from './bunker/bunker.component';
import { TestingComponent } from './testing/testing.component';
import { AppComponent } from './app.component';
import { RouterOutlet } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { BunkerComponent } from './bunker/bunker.component';

@NgModule({
  declarations: [
    AppComponent,
    TestingComponent,
    LoginComponent,
    LoadingDialogComponent,
    HomeComponent,
    HeaderToolbarComponent,
    SignoutComponent,
    TodoListingComponent,
    TodoComponent,
    HitratePipe,
    CommentDialogComponent,
    NewTodoComponent,
    EditTodoComponent,
    TvListingComponent,
    EditSkuComponent,
    TvInventoryComponent,
    TvImportComponent,
    StatsComponent,
    ProgressRingComponent,
    TonersComponent,
    ToolsComponent,
    E403Component,
    ManageSelfComponent,
    ManageUsersComponent,
    WarehouseComponent,
    WarehouseLocationComponent,

    WarehousePositionComponent,
    HeadCountComponent,

    BunkerComponent,


  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatCardModule,
    MatIconModule,
    BrowserAnimationsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatInputModule,
    MatToolbarModule,
    AngularFireAuthGuardModule,
    FormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule

  ],
  providers: [AngularFireAuthGuard, AuthGuard, AngularFireAuth],
  entryComponents: [CommentDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
