<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header-toolbar [sidenav]="sidenav" showBack="true">
    </app-header-toolbar>
    <!-- <table mat-table [dataSource]="todos$">  <ng-container matColumnDef="title">    <th mat-header-cell *matHeaderCellDef>TODO-lista</th>    <td mat-cell *matCellDef="let todo">{{ todo.title }}</td>  </ng-container>  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>  <tr mat-row *matRowDef="let todo; columns: displayedColumns"></tr></table> -->
    <div class="content-container">
      <h1><span style="flex: 1">RTG TV</span></h1>
      <div class="todo-cards">
        <div
          [routerLink]="['/todo', todo.id]"
          style="cursor: pointer"
          *ngFor="let todo of todos$ | async"
        >
          <mat-card
            class="mat-elevation-z8"
            style="padding: 10px 5px !important"
          >
            <h2>{{ todo.title }}</h2>
            <p>Deadline: {{ todo.deadline }}</p>
            <p *ngIf="getUser(todo.createdBy) as user">
              Skapad av: {{ user.displayName }}
            </p>
            <div class="mt20 mb20">
              <mat-progress-bar
                mode="determinate"
                value="{{ getProgress(todo.doneAmount, todo.goalAmount) }}"
              ></mat-progress-bar>
            </div>
            <p style="text-align: center">
              {{ todo.doneAmount }} / {{ todo.goalAmount }}
              <small> ( {{ todo.goalAmount - todo.doneAmount }} kvar ) </small>
            </p>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="fab" *ngIf="userService.User | async as User">
      <button
        *ngIf="User.HasPermission(1)"
        color="primary"
        mat-fab
        (click)="createNewTodo()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
