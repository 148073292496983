import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(private auth: AngularFireAuth, private router: Router) { }

  async ngOnInit() {
    await this.auth.auth.signOut();
    this.router.navigateByUrl('login');
  }

}
