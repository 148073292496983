<div class="content-container" style="padding-top: 30px">
  <div style="margin: 0 auto">
    <p>CGM Filter</p>
    <ul>
      <li>431 Mobile phones</li>
      <li>440 Watches</li>
      <li>519 Laptop Apple</li>
      <li>520 Laptop Chromebooks</li>
      <li>521 Laptop Commercial</li>
      <li>522 Laptop Gaming</li>
      <li>525 Tablet Apple</li>
      <li>526 Desktop Apple</li>
      <li>533 Laptop Windows</li>
      <li>534 Tablet Android</li>
    </ul>
  </div>
  <mat-card class="mat-elevation-z2 mb30">
    <div style="padding: 30px">
      <h2 style="margin-top: 0">Ladda upp Packingnote</h2>

      <div
        style="display: flex; justify-content: flex-end; align-items: center"
      >
        <label for="packingnoteInput" style="flex: 1; cursor: pointer">
          <input
            type="file"
            #packingnoteInput
            id="packingnoteInput"
            accept="application/pdf"
            (change)="onFileChange($event)"
          />
          {{ packingnoteInputLabel }}
        </label>
        <button mat-raised-button color="primary" (click)="uploadPackingnote()">
          Ladda upp
        </button>
      </div>
    </div>
  </mat-card>

  <mat-card class="mat-elevation-z2 mb30" *ngIf="validated">
    <div style="padding: 30px">
      <h2 style="margin-top: 0">Ladda upp CGM</h2>

      <div
        style="display: flex; justify-content: flex-end; align-items: center"
      >
        <label for="cgmInput" style="flex: 1; cursor: pointer">
          <input
            type="file"
            #cgmInput
            multiple
            id="cgmInput"
            accept="text/csv"
            (change)="onCGM($event)"
          />
          {{ cgmInputLabel }}
        </label>
        <button mat-raised-button color="primary" (click)="parseCGM()">
          Ladda upp
        </button>
      </div>
    </div>
  </mat-card>
</div>

<app-loading-dialog
  [loadingState]="loadingState"
  [messages]="messages"
></app-loading-dialog>
