import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginComponent } from './login/login.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomeComponent } from './home/home.component';
import { User } from './models/User';
import { SignoutComponent } from './signout/signout.component';
import { TodoListingComponent } from './todo/listing/todo-listing.component';
import { TodoComponent } from './todo/todo/todo.component';
import { HitratePipe } from './Hitrate.pipe';
import { PERMISSIONS } from './models/Permissions';
import { NewTodoComponent } from './todo/new/new-todo/new-todo.component';
import { EditTodoComponent } from './todo/edit-todo/edit-todo.component';
import { TvListingComponent } from './tv/tv-listing/tv-listing.component';
import { EditSkuComponent } from './edit-sku/edit-sku.component';
import { TvInventoryComponent } from './tv/tv-inventory/tv-inventory.component';
import { TvImportComponent } from './tv/tv-import/tv-import.component';
import { StatsComponent } from './stats/stats.component';
import { TonersComponent } from './toners/toners.component';

import { E403Component } from './errors/e403/e403.component';
import { ManageSelfComponent } from './manage-self/manage-self.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { auth } from 'firebase';
import { TestingComponent } from './testing/testing.component';


import { BunkerComponent } from './bunker/bunker.component';
import { ToolsComponent } from './tools/tools.component';
import { HeadCountComponent } from './head-count/head-count.component';
import axios, { AxiosPromise } from 'axios';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private router: Router) {
    this.afAuth = afAuth;
    this.db = db;
    this.router = router;
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Promise<boolean | UrlTree> {
    let returnUrl = '';
    if (route.url.length > 0) {
      const path = route.url.map(segment => segment.path).join('/');
      returnUrl = `?returnUrl=${path}`;
    }

    const stayAliveIP: string[] | null = route.data.stayAliveIP || null;

    if (stayAliveIP) {
      const result = await (axios('https://api.ipify.org?format=json', { responseType: 'json', }) as AxiosPromise<{ ip: string }>);
      const IP = result.data.ip;

      // const result = await fetch('https://api.ipify.org?format=json', {
      //   method: 'GET',
      //   mode: 'no-cors',
      //   // headers: {
      //   //   'Access-Control-Allow-Origin': '*'
      //   // }
      // });
      // console.log(result);

      // const data = await result.json();

      if (stayAliveIP.includes(IP)) {
        const user = auth().currentUser;

        if (!user) {
          await auth().signInWithEmailAndPassword('aftersales.svagertorp@giganten.se', 'elgig2056');
        }
      }
    }


    const afUser = await this.afAuth.user.pipe(take(1)).toPromise();
    if (!afUser) {
      const tree = this.router.parseUrl(`login${returnUrl}`);
      return Promise.resolve(tree);
    }

    const accessPermission = route.data.permission || 0;
    if (accessPermission > 0) {
      const userDoc = await this.db.collection('users').doc(afUser.uid).get().toPromise();
      const iUser = Object.assign({}, userDoc.data(), { id: userDoc.id, ref: userDoc.ref });
      const user = new User(iUser);
      if (user.HasPermission(accessPermission)) {
        return Promise.resolve(true);
      } else {
        const tree = this.router.parseUrl('403');
        return Promise.resolve(tree);
      }
    }
    return Promise.resolve(true);


  }

}

const redirectUnauthorizedToLogin = (next) => {
  return redirectUnauthorizedTo(['login']);
};

const redirectLoggedInToHome = (next) => {
  return redirectLoggedInTo(['']);
};

const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'bunker',
    component: BunkerComponent
  },
  {
    path: 'tools',
    component: ToolsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'signout',
    component: SignoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: '403',
    component: E403Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.MANAGE_STATS }
  },
  {
    path: 'toners',
    component: TonersComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.MANAGE_TONERS }
  },
  {
    path: 'tv',
    component: TvListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tv/import',
    component: TvImportComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.IMPORT_TV }
  },
  {
    path: 'tv/inventory',
    component: TvInventoryComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.INVENTORY_TV }
  },
  {
    path: 'todo',
    component: TodoListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'todo/new',
    component: NewTodoComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.CREATE_TODO }
  },
  {
    path: 'todo/:id',
    component: TodoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'todo/edit/:id',
    component: EditTodoComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.EDIT_TODO }
  },
  {
    path: 'sku/edit/:sku',
    component: EditSkuComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.EDIT_TV }
  },
  {
    path: 'manage-self',
    component: ManageSelfComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.MANAGE_SELF }
  },
  {
    path: 'manage-users',
    component: ManageUsersComponent,
    canActivate: [AuthGuard],
    data: { permission: PERMISSIONS.MANAGE_USERS }
  },
  {
    path: 'warehouse',
    component: WarehouseComponent,
    canActivate: [AuthGuard],
    data: { stayAliveIP: ['92.39.46.11', '194.248.0.190', '62.181.219.186'] }
  },
  {
    path: 'headcount',
    component: HeadCountComponent,
  }

];





@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: [

  ]
})
export class AppRoutingModule { }
