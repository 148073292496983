import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PERMISSIONS } from '../models/Permissions';
import { UserService } from '../services/User.service';

@Component({
  selector: 'app-edit-sku',
  templateUrl: './edit-sku.component.html',
  styleUrls: ['./edit-sku.component.scss']
})
export class EditSkuComponent implements OnInit {

  sku: string;
  model: string;
  available = 0;
  rtg = 0;
  premium = false;
  ean = '';
  Permissions = PERMISSIONS;

  skuItem$: Observable<any>;

  constructor(public db: AngularFirestore, public route: ActivatedRoute, public location: Location, public userService: UserService) { }

  ngOnInit() {
    this.skuItem$ = this.route.paramMap.pipe(switchMap(paramMap => {
      this.sku = paramMap.get('sku');
      return this.db.collection('skus').doc(this.sku)
        .snapshotChanges()
        .pipe(map(({ payload }) => {
          const sku = Object.assign({ stock: { available: 0, rtg: 0 } }, payload.data(), { id: payload.id, ref: payload.ref });
          return sku;
        }));
    }));
    this.skuItem$.subscribe(s => {
      this.model = s.model;
      this.available = s.stock.available;
      this.rtg = s.stock.rtg;
      this.ean = s.ean;
      this.premium = s.premium || false;
    });
  }

  async onSubmit() {

    await this.db.doc(`skus/${this.sku}`).update({
      model: this.model,
      ean: this.ean,
      stock: {
        available: this.available,
        rtg: this.rtg
      },
      premium: this.premium
    });
    this.goBack();

  }
  goBack() {
    this.location.back();
  }

}
