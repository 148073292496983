import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { PERMISSIONS } from '../models/Permissions';
import { UserService } from '../services/User.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  permissions = PERMISSIONS;

  matrixAtoE = [
    [11, 21, 31, 0, 41, 51],
    [12, 22, 32, 0, 42, 52],
    [13, 23, 33, 0, 43, 53],
    [14, 24, 34, 0, 44, 54],
  ];

  matrixFtoH = [
    [11, 0, 21, 0, 31, 41, 0, 51, 61],
    [12, 0, 22, 0, 32, 42, 0, 52, 62],
    [13, 0, 23, 0, 33, 43, 0, 53, 63],
    [14, 0, 24, 0, 34, 44, 0, 54, 64],
  ];

  searchTerm = '';

  constructor(public userService: UserService) { }

  ngOnInit() {
    // this.searchTerm.valueChanges.pipe<string>(startWith('')).subscribe(this.search);
  }

  search() {
    if (this.searchTerm.length > 2) {
      const cells = Array.from(document.querySelectorAll('.cell'));

      const cellsMatch = cells.filter(c => c.innerHTML.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()));

      cells.forEach(c => c.classList.remove('highlight'));

      if (cellsMatch.length > 0) {
        cellsMatch[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

        cellsMatch.forEach(cell => cell.classList.add('highlight'));

        setTimeout(() => {
          cellsMatch.forEach(cell => cell.classList.remove('highlight'));
        }, 20 * 1000);
      }
    }


  }

}
