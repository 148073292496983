import { Component, Input, OnInit } from '@angular/core';

export enum LoadingState {
  IDLE = 0,
  WORKING = 1,
  DONE = 2,
  INFO = 3,
  ERROR = 4,
}

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  @Input()
  loadingState: LoadingState;

  @Input()
  messages: any;

  icons: any;

  constructor() {
    this.loadingState = LoadingState.IDLE;
    this.icons = {
      [LoadingState.WORKING]: '<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>'
    };
  }

  ngOnInit() {
  }
  isVisible() {
    return this.loadingState !== LoadingState.IDLE;
  }

}
