import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TonerColor, TonerTranslation } from '../models/Toner';

@Component({
  selector: 'app-toners',
  templateUrl: './toners.component.html',
  styleUrls: ['./toners.component.scss']
})
export class TonersComponent implements OnInit {

  tonerTranslation = TonerTranslation;
  displayedColumns = ['description', 'color', 'amount', 'minimum', 'ordered', 'printers'];
  isEditing = false;
  tonerModalVisible = false;
  selectedToner = null;
  colors = [];
  color: string;
  description = '';
  amount = '';
  minimum = '';
  ordered = '';
  printers = '';
  term = new FormControl('');

  term$: Observable<string>;
  toners$: Observable<any>;
  filteredToners$: Observable<any>;

  constructor(private db: AngularFirestore) {
    this.term$ = this.term.valueChanges.pipe(startWith(''));
    this.toners$ = this.db.collection('toners').snapshotChanges()
      .pipe(map(actions => {
        const result = actions
          .map(({ payload }) => {
            return Object.assign({}, payload.doc.data(), { id: payload.doc.id, ref: payload.doc.ref });
          })
          .sort((a: any, b: any) => a.color.localeCompare(b.color))
          .sort((a: any, b: any) => a.description.localeCompare(b.description));
        return result;
      }));
    this.filteredToners$ = combineLatest(this.toners$, this.term$)
      .pipe(map(([toners, term]) => {
        if (term.length >= 2) {
          return toners.filter(toner => {
            return toner.description.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
              toner.printers.join(';').toLocaleLowerCase().includes(term.toLocaleLowerCase());
          });
        }
        return toners;
      }));
    Object.keys(TonerColor).forEach(key => {
      this.colors.push(TonerColor[key]);
    });
  }

  ngOnInit() {
  }

  addToner() {
    this.tonerModalVisible = true;
    this.isEditing = false;
  }
  editToner(toner) {
    this.tonerModalVisible = true;
    this.isEditing = true;
    this.selectedToner = toner;
    this.description = toner.description;
    this.color = toner.color;
    this.amount = toner.amount.toString();
    this.minimum = toner.minimum.toString();
    this.ordered = toner.ordered.toString();
    this.printers = toner.printers.join('\n');
  }
  cancel() {
    this.tonerModalVisible = false;
    this.isEditing = false;
    this.description = '';
    this.color = undefined;
    this.amount = '';
    this.minimum = '';
    this.ordered = '';
    this.printers = '';
  }
  saveToner() {
    const amount = isNaN(parseInt(this.amount, 10)) ? 0 : parseInt(this.amount, 10);
    const minimum = isNaN(parseInt(this.minimum, 10)) ? 0 : parseInt(this.minimum, 10);
    const ordered = isNaN(parseInt(this.ordered, 10)) ? 0 : parseInt(this.ordered, 10);
    const toner = {
      description: this.description,
      color: this.color,
      amount,
      minimum,
      ordered,
      printers: this.printers.split('\n'),
      ean: '',
    };
    this.selectedToner.ref.update(toner);
    this.cancel();
  }
  async createToner() {

    const amount = isNaN(parseInt(this.amount, 10)) ? 0 : parseInt(this.amount, 10);
    const minimum = isNaN(parseInt(this.minimum, 10)) ? 0 : parseInt(this.minimum, 10);
    const ordered = isNaN(parseInt(this.ordered, 10)) ? 0 : parseInt(this.ordered, 10);
    const toner = {
      description: this.description,
      color: this.color,
      amount,
      minimum,
      ordered,
      printers: this.printers.split('\n'),
      ean: '',
    };
    console.log('createToner', this.description, toner);
    this.db.collection('toners').add(toner);
    this.cancel();

  }
  deleteToner() {
    if (confirm('Ta bort toner?')) {
      this.selectedToner.ref.delete();
      this.cancel();
    }
  }
}
