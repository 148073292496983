import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PERMISSIONS } from 'src/app/models/Permissions';
import { UserService } from 'src/app/services/User.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-todo',
  templateUrl: './edit-todo.component.html',
  styleUrls: ['./edit-todo.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }

  ]
})
export class EditTodoComponent implements OnInit {

  displayedColumns = ['sku', 'model', 'goalAmount', 'premium', 'actions'];
  todoItems = [];
  deadline = new FormControl(new Date());
  selectedTodo = null;
  activeSKU = null;
  Permissions = PERMISSIONS;

  todoId: string;
  title: string;
  sku: string;
  model: string;
  goalAmount: string;

  todo$: Observable<any>;
  todoItems$: Observable<any>;
  total$: Observable<any>;

  isEditing = () => this.selectedTodo !== null;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private router: Router, public userService: UserService) { }

  async ngOnInit() {

    this.todo$ = this.route.paramMap.pipe(switchMap(paramMap => {
      this.todoId = paramMap.get('id');
      return this.db
        .doc(`todo/${this.todoId}`)
        .snapshotChanges()
        .pipe(map(({ payload }) => {
          const todo = payload.data() as any;
          const ref = payload.ref;
          const timestamp = todo.deadline;
          const deadline = moment(timestamp.toDate()).format('YYYY-MM-DD');
          todo.deadline = deadline;
          return Object.assign({}, todo, { ref, id: this.todoId });
        }));
    }));
    this.todoItems$ = this.todo$.pipe(switchMap(todo => {
      const o = this.db
        .doc(`todo/${todo.id}`)
        .collection('items')
        .snapshotChanges()
        .pipe(map(docs => docs
          .map(({ payload }) => {
            const todoItem = payload.doc.data();
            const id = payload.doc.id;
            const ref = payload.doc.ref;
            const skuRef = todoItem.ref;
            return Object.assign({}, todoItem, { id, ref, skuRef });
          })
          .sort((a, b) => this.sortListByProgress(a, b))), docs => {
            return docs.pipe(map(d => d.map(s => (Object.assign({}, s, { realSku: this.db.doc(`skus/${s.sku}`).valueChanges() })))));
          });
      return o;
    }));
    this.todoItems$.subscribe(todoItems => {
      this.todoItems = todoItems;
      this.db.doc(`todo/${this.todoId}`).update({
        goalAmount: this.getTotalTodo()
      });
    });
    this.todo$.subscribe(todo => {
      this.title = todo.title;
      this.deadline = new FormControl(new Date(todo.deadline));
    });
    this.total$ = this.todoItems$.pipe(map(items => {
      const doneAmount = items.reduce((a, c) => a + c.doneAmount, 0);
      const goalAmount = items.reduce((a, c) => a + c.goalAmount, 0);
      return { doneAmount, goalAmount };
    }));
  }

  onDeadlineChange(event) {
    this.deadline = new FormControl(event.value.toDate());
  }
  sortListByProgress(a, b) {
    const aDone = this.isItemDone(a);
    const bDone = this.isItemDone(b);
    if (aDone && bDone) {
      return 0;
    }
    if (aDone) {
      return 1;
    }
    if (bDone) {
      return -1;
    }
  }
  isItemDone(item) {
    return item.doneAmount >= item.goalAmount;
  }
  getTotalTodo() {
    return this.todoItems.reduce((a, c) => a + c.goalAmount, 0);
  }
  async onSubmit() {

    await this.db.doc(`todo/${this.todoId}`).update({
      title: this.title,
      deadline: this.deadline.value,
      goalAmount: this.getTotalTodo()
    });
    this.router.navigateByUrl(`todo/${this.todoId}`);

  }
  onEdit(item) {
    this.selectedTodo = item;
    this.sku = item.sku;
    this.model = item.model;
    this.goalAmount = item.goalAmount.toString();
    // this.activeSKU = item.realSku;
  }
  log(arg) {
    console.log(arg);
  }
  saveChanges() {
    const goalAmount = parseInt(this.goalAmount, 10);
    if (isNaN(goalAmount) || goalAmount < 0) {
      alert('Valt antal är ogiltigt');
      return;
    }
    this.selectedTodo.ref.update({
      model: this.model,
      goalAmount
    });
    this.selectedTodo.skuRef.update({
      model: this.model
    });
    this.clear();
  }
  clear() {
    this.selectedTodo = null;
    this.sku = '';
    this.model = '';
    this.goalAmount = '';
  }
  async onAdd() {

    const goalAmount = parseInt(this.goalAmount, 10);
    if (isNaN(goalAmount) || goalAmount < 0) {
      alert('Valt antal är ogiltigt');
      return;
    }
    const todo = this.todoItems.find(t => t.sku === this.sku);
    if (todo) {
      console.log('todo found');
      console.log(todo);
      alert(`${this.sku} finns redan i listan`);
      return;
    } else {
      // Add sku to db and to list
      console.log('inserting todo');
      await this.db.doc(`skus/${this.sku}`).set({ sku: this.sku, model: this.model });
      await this.db.doc(`todo/${this.todoId}`).collection('items').add({
        doneAmount: 0,
        goalAmount,
        model: this.model,
        sku: this.sku,
        ref: this.db.doc(`skus/${this.sku}`).ref
      });
      this.clear();
    }

  }
  async searchSKU() {

    if (this.sku) {
      this.model = '';
      const sku = await this.db.doc(`skus/${this.sku}`).get();
      const skuSnapshot = await sku.toPromise();
      if (skuSnapshot.exists) {
        const todo = skuSnapshot.data();
        this.model = todo.model;
      }
    }

  }
  removeItem(item) {
    const res = confirm(`Ta bort "${item.sku}${item.model.length > 0 ? ' - ' : ''}${item.model}" från listan?`);
    if (res) {
      item.ref.delete();
      this.clear();
    }
  }
  async onDelete() {

    const res = confirm('Ta bort lista? Detta går inte att ångra');
    if (res) {
      this.router.navigateByUrl('todo');
      this.todo$.subscribe(t => t.ref.delete());
    }

  }

}
