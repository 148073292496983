<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <div class="content-container">
      <div class="mb20" *ngIf="ordered.length > 0">
        <mat-card>
          <div style="padding: 20px">
            <h3 style="margin: 0" (click)="picker.open()">
              Vecka {{ currentWeek }} ({{ currentYear }})
            </h3>
          </div>
        </mat-card>
      </div>
      <div class="grid mb20">
        <div *ngIf="ordered.length > 0">
          <mat-card>
            <div style="padding: 20px">
              <app-progress-ring
                [progress]="100"
                [amount]="totalDone"
                radius="50"
                stroke="5"
                label="Totalt"
              ></app-progress-ring>
            </div>
          </mat-card>
        </div>
        <div *ngFor="let item of ordered">
          <mat-card>
            <div style="padding: 20px">
              <app-progress-ring
                [progress]="item.percentage"
                [amount]="item.amountDone"
                radius="50"
                stroke="5"
                [label]="item.user.displayName"
              ></app-progress-ring>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="mb20">
        <mat-card>
          <div style="padding: 20px" class="week-chart-container">
            <h3 style="margin: 0 0 20px 0" (click)="picker.open()">
              Vecka {{ currentWeek }} ({{ currentYear }})
            </h3>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
<div [hidden]="true">
  <mat-form-field appearance="fill">
    <mat-label>Vecka</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      disabled
      autocomplete="off"
      (dateChange)="onWeekChange($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      touchUi
      disabled="false"
      startView="month"
    ></mat-datepicker>
  </mat-form-field>
</div>
