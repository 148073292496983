import { Component, OnInit } from '@angular/core';
import { firestore } from 'firebase';
import * as moment from 'moment';
import { StatType } from '../models/LogStat';
import { User } from '../models/User';
import Chart from 'chart.js';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class StatsComponent implements OnInit {

  firstOfMonth = moment().startOf('month');
  endOfMonth = moment().endOf('month');
  firstOfWeek = moment().startOf('isoWeek');
  endOfWeek = moment().endOf('isoWeek');
  currentWeek = moment().format('W');
  currentYear = moment().format('YYYY');
  charts = [];
  singleUserStat = {};
  ordered = [];
  loaded = false;
  stats = [];
  totalDone: number;

  weekChart: any;

  constructor() { }

  async ngOnInit() {
    const statisticsSnapshot = await firestore()
      .collection('statistics')
      // .where('timestamp', '>=', firestore.Timestamp.fromDate(this.firstOfMonth.toDate()))
      // .where('timestamp', '<=', firestore.Timestamp.fromDate(this.endOfMonth.toDate()))
      .get();
    // TODO: Get all stats and filter in app
    this.stats = statisticsSnapshot.docs.map(s => (Object.assign({}, s.data())));
    await this.initUserStats();
    console.log(this.singleUserStat);
    this.initProgressRings();
    console.log(this.ordered);
    this.loaded = true;
    // this.initCharts();
    this.initWeekChart();
  }

  async initUserStats() {

    this.totalDone = this.stats.filter(stat => {
      return stat.timestamp >= firestore.Timestamp.fromDate(this.firstOfWeek.toDate()) &&
        stat.timestamp <= firestore.Timestamp.fromDate(this.endOfWeek.toDate());
    }).reduce((a, stat) => {
      if (stat.type === StatType.TV_ADD) {
        return a + 1;
      } else {
        return a - 1;
      }
    }, 0);
    this.singleUserStat = {};
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.stats.length; index++) {
      const stat = this.stats[index];
      if (stat.timestamp >= firestore.Timestamp.fromDate(this.firstOfWeek.toDate()) &&
        stat.timestamp <= firestore.Timestamp.fromDate(this.endOfWeek.toDate())) {
        if (this.singleUserStat[stat.uid] === undefined) {
          this.singleUserStat[stat.uid] = { stats: [], user: null, amountDone: 0, percentage: 0 };
          const userSnap = await stat.userRef.get();
          const userData = Object.assign({}, userSnap.data(), { id: userSnap.id, ref: userSnap.ref });
          this.singleUserStat[stat.uid].user = new User(userData);
        }
        this.singleUserStat[stat.uid].stats.push(stat);
        const amountDone = this.singleUserStat[stat.uid].stats.reduce((a, c) => {
          if (c.type === StatType.TV_ADD) {
            return a + 1;
          } else {
            return a - 1;
          }
        }, 0);
        const percentage = Math.round(amountDone / this.totalDone * 100);
        this.singleUserStat[stat.uid].amountDone = amountDone;
        this.singleUserStat[stat.uid].percentage = percentage;
      }
    }
    this.singleUserStat = Object.assign({}, this.singleUserStat);

  }

  initWeekChart() {
    const colors = {
      0: { backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)' },
      1: { backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)' },
      2: { backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)' },
      3: { backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)' },
      4: { backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)' },
      5: { backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)' },
      6: { backgroundColor: 'rgba(200, 255, 0, 0.2)', borderColor: 'rgba(200, 255, 0, 1)' },
    };
    const data: { labels?: string[], datasets?: any } = {};
    data.labels = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
    data.datasets = [];
    const formattedData = {};
    Object.keys(this.singleUserStat).forEach(key => {
      const userData = this.singleUserStat[key];
      const stats = userData.stats.filter(stat => {
        return stat.timestamp >= firestore.Timestamp.fromDate(this.firstOfWeek.toDate()) &&
          stat.timestamp <= firestore.Timestamp.fromDate(this.endOfWeek.toDate());
      });
      if (stats.length > 0) {
        formattedData[key] = { user: userData.user, stats };
      }
    });
    let colorIndex = 0;
    Object.keys(formattedData).forEach(key => {
      const userData = formattedData[key];
      const dataset: any = {};
      // Pick colors
      const colorsPicked = colors[colorIndex];
      colorIndex = colorIndex + 1;
      dataset.backgroundColor = colorsPicked.backgroundColor;
      dataset.borderColor = colorsPicked.borderColor;
      dataset.borderWidth = 1;
      dataset.label = userData.user.displayName;
      dataset.data = [0, 0, 0, 0, 0, 0, 0];
      userData.stats.forEach(stat => {
        const dataIndex = parseInt(moment(stat.timestamp.toDate()).format('E'), 10) - 1;
        if (stat.type === StatType.TV_ADD) {
          dataset.data[dataIndex] = dataset.data[dataIndex] + 1;
        } else if (stat.type === StatType.TV_SUB) {
          dataset.data[dataIndex] = dataset.data[dataIndex] - 1;
        }
      });
      data.datasets.push(dataset);
    });
    if (this.weekChart) {
      this.weekChart.clear();
      this.weekChart.data = data;
      this.weekChart.update();
      this.weekChart.render();
      return;
    }
    const canvas = document.createElement('canvas');
    document.body.querySelector(`.week-chart-container`).append(canvas);
    this.weekChart = new Chart(canvas, {
      type: 'bar',
      data,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 1,
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  initCharts() {
    Object.keys(this.singleUserStat).forEach(key => {
      const canvasHolder = document.createElement('div');
      canvasHolder.classList.add('canvas-holder');
      const canvas = document.createElement('canvas');
      canvas.id = `canvas-${key}`;
      canvasHolder.append(canvas);
      const stat = this.singleUserStat[key];
      const amountDone = stat.stats.reduce((a, c) => {
        if (c.type === StatType.TV_ADD) {
          return a + 1;
        } else {
          return a - 1;
        }
      }, 0);
      const percentage = amountDone / this.totalDone * 100;
      document.body.querySelector(`.single-user-stats-grid`).append(canvasHolder);
      const chart = new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: [stat.user.displayName],
          datasets: [{
            data: [percentage, 100],
            borderColor: ['#36a2eb', '##212121'],
            backgroundColor: ['#36a2eb', '##212121']
          },
          ]
        },
        options: {
          cutoutPercentage: 98,
          responsive: false
          // rotation: Math.PI,
          // circumference: Math.PI * percentage
        }
      });
      this.charts.push(chart);
    });
    this.charts.forEach(c => c.resize());
  }

  initProgressRings() {
    this.ordered = [];
    Object.keys(this.singleUserStat).sort((keyOne, keyTwo) => {
      const a = this.singleUserStat[keyOne];
      const b = this.singleUserStat[keyTwo];
      if (a.amountDone === b.amountDone) {
        return 0;
      }
      if (a.amountDone > b.amountDone) {
        return -1;
      }
      return 1;
    })
      .forEach(key => {
        const userData = this.singleUserStat[key];
        const stats = userData.stats.filter(stat => {
          return stat.timestamp >= firestore.Timestamp.fromDate(this.firstOfWeek.toDate()) &&
            stat.timestamp <= firestore.Timestamp.fromDate(this.endOfWeek.toDate());
        });
        if (stats.length > 0) {
          this.ordered.push(this.singleUserStat[key]);
        }
      });
    this.totalDone = this.stats
      .filter(stat => {
        return stat.timestamp >= firestore.Timestamp.fromDate(this.firstOfWeek.toDate()) &&
          stat.timestamp <= firestore.Timestamp.fromDate(this.endOfWeek.toDate());
      })
      .reduce((a, stat) => {
        if (stat.type === StatType.TV_ADD) {
          return a + 1;
        } else {
          return a - 1;
        }
      }, 0);
  }

  resize() {
    this.charts.forEach(c => c.resize());
  }
  async onWeekChange(event) {

    this.currentWeek = event.value.format('W');
    this.currentYear = event.value.format('YYYY');
    this.firstOfWeek = event.value.clone().startOf('isoWeek');
    this.endOfWeek = event.value.clone().endOf('isoWeek');
    await this.initUserStats();
    this.initWeekChart();
    this.initProgressRings();

  }

}
