import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { LoadingState } from 'src/app/components/loading-dialog/loading-dialog.component';
import { PERMISSIONS } from 'src/app/models/Permissions';
import { UserService } from 'src/app/services/User.service';

@Component({
  selector: 'app-tv-import',
  templateUrl: './tv-import.component.html',
  styleUrls: ['./tv-import.component.scss']
})
export class TvImportComponent implements OnInit {

  uploadLabelText = 'Ingen fil har valts (TV lista, icast.csv)';
  uploadEANLabelText = 'Ingen fil har valts (EAN)';
  Permissions = PERMISSIONS;
  loadingState = LoadingState.IDLE;
  messages = {
    [LoadingState.WORKING]: 'WORKING'
  };

  file: any;
  eanFile: any;
  textArea: string;

  constructor(private db: AngularFirestore, private location: Location, public userService: UserService) { }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  onFileChange($event) {
    if ($event.target.files.length === 1) {
      this.uploadLabelText = $event.target.files[0].name;
    } else {
      this.uploadLabelText = 'Ingen fil har valts (TV lista, icast.csv)';
    }
  }
  onEANFileChange($event) {
    if ($event.target.files.length === 1) {
      this.uploadEANLabelText = $event.target.files[0].name;
    } else {
      this.uploadEANLabelText = 'Ingen fil har valts (EAN)';
    }
  }
  async uploadFile() {

    const fileList = this.file.nativeElement.files;
    if (fileList.length !== 1) {
      alert('Fil saknas');
      return;
    }
    if (fileList[0].name !== 'icast.csv') {
      alert('Ogiltig fil');
    }
    const file = fileList.item(0);
    const text = await file.text();
    let lines = text.split('\n');
    lines = lines.slice(1, lines.length - 3);
    const importedSkus = [];
    lines.forEach((line) => {
      const parts = line.split(';');
      const description = parts[1].toLocaleLowerCase();
      // Skip importing OUTLET products
      if (description.includes('outlet') === false) {
        if (description.includes('used') === false) {
          importedSkus.push({
            sku: parts[0],
            available: parseInt(parts[3], 10),
          });
        }
      }
    });
    this.loadingState = LoadingState.WORKING;
    this.messages[LoadingState.WORKING] = `${importedSkus.length} linjer hittades`;
    console.log(`${importedSkus.length} linjer hittades`);
    await this.sleep(1000);
    this.messages[LoadingState.WORKING] = 'Återställer lagersaldo';
    console.log('Återställer lagersaldo');
    const dbSkus = (await firestore().collection('skus').get()).docs.map(doc => (Object.assign({}, doc.data())));
    const reset = dbSkus.map(sku => {
      return this.db.doc(`skus/${sku.sku}`).update({
        'stock.available': 0
      });
    });
    await Promise.all(reset);
    this.messages[LoadingState.WORKING] = 'Lagersaldo återställd';
    console.log('Lagersaldo återställd');
    await this.sleep(1000);
    this.messages[LoadingState.WORKING] = 'Korrigerar nytt lagersaldo';
    console.log('Korrigerar nytt lagersaldo');
    const newStock = importedSkus.map(sku => {
      const dbSku = dbSkus.find(s => s.sku === sku.sku);
      if (dbSku) {
        let rtg = 0;
        if (dbSku.stock && dbSku.stock.rtg) {
          rtg = dbSku.stock.rtg;
        }
        return this.db.doc(`skus/${sku.sku}`)
          .update({
            cg: { c: 2, g: 24 },
            sku: sku.sku,
            'stock.available': sku.available,
            'stock.rtg': rtg
          });
      } else {
        return this.db.doc(`skus/${sku.sku}`)
          .set({
            sku: sku.sku,
            model: '',
            stock: {
              rtg: 0,
              available: sku.available,
            },
            cg: { c: 2, g: 24 }
          });
      }
    });
    await Promise.all(newStock);
    this.loadingState = LoadingState.DONE;
    this.messages[LoadingState.DONE] = 'Lagersaldo korrigerat';
    console.log('Lagersaldo korrigerat');
    await this.sleep(2000);
    this.loadingState = LoadingState.IDLE;
    this.file.nativeElement.value = '';
    this.uploadLabelText = 'Ingen fil har valts (TV lista, icast.csv)';

  }
  async uploadEANFile() {

    const fileList = this.eanFile.nativeElement.files;
    if (fileList.length !== 1) {
      alert('Fil saknas');
      return;
    }
    const file = fileList.item(0);
    const text = await file.text();
    let lines = text.split('\n');
    lines = lines.slice(1, lines.length - 1);
    const eanFileItems = [];
    lines.forEach(line => {
      const parts = line.split(';');
      const ItemID = parts[1].replace('"', '').replace('"', '');
      const EAN = parts[6].replace('"', '').replace('"', '');
      if (EAN !== '0') {
        eanFileItems.push({ ItemID, EAN });
      }
    });
    console.log(eanFileItems);
    this.loadingState = LoadingState.WORKING;
    this.messages[LoadingState.WORKING] = `${eanFileItems.length} linjer hittades`;
    console.log(`${eanFileItems.length} linjer hittades`);
    await this.sleep(1000);
    const dbSkus = (await firestore().collection('skus').get()).docs.map(doc => (Object.assign({}, doc.data(), { ref: doc.ref })));
    const updates = [];
    dbSkus.forEach(dbSKU => {
      const eanFileItem = eanFileItems.find(item => item.ItemID === dbSKU.sku);
      if (eanFileItem) {
        updates.push(dbSKU.ref.update({
          ean: eanFileItem.EAN
        }));
      }
    });
    this.loadingState = LoadingState.WORKING;
    this.messages[LoadingState.WORKING] = `Uppdaterar databas`;
    await Promise.all(updates);
    console.log('Done');
    this.loadingState = LoadingState.DONE;
    this.messages[LoadingState.DONE] = 'Databas uppdaterat';
    await this.sleep(2000);
    this.loadingState = LoadingState.IDLE;
    this.eanFile.nativeElement.value = '';
    this.uploadEANLabelText = 'Ingen fil har valts (EAN)';

  }
  async import() {

    const password = prompt('Lösenord');
    if (password !== 'gig2056') {
      alert('Fel lösenord!');
      return;
    }
    const rows = this.textArea.split('\n');
    const skus = [];
    rows.forEach(row => {
      const parts = row.split(';');
      skus.push({ sku: parts[0], available: parseInt(parts[2], 10) });
    });
    console.log(skus);
    const dbSkus = (await firestore().collection('skus').get()).docs.map(doc => (Object.assign({}, doc.data())));
    skus.forEach(sku => {
      const dbSku = dbSkus.find(s => s.sku === sku.sku);
      if (dbSku) {
        let rtg = 0;
        if (dbSku.stock && dbSku.stock.rtg) {
          rtg = dbSku.stock.rtg;
        }
        this.db.doc(`skus/${sku.sku}`)
          .update({
            cg: { c: 2, g: 24 },
            sku: sku.sku,
            'stock.available': sku.available,
            'stock.rtg': rtg
          });
      } else {
        this.db.doc(`skus/${sku.sku}`)
          .set({
            sku: sku.sku,
            model: '',
            stock: {
              rtg: 0,
              available: sku.available
            },
            cg: { c: 2, g: 24 }
          });
      }
    });
    this.textArea = '';

  }

}
