<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar [sidenav]="sidenav"></app-header-toolbar>
    </div>
    <div
      class="content-container"
      style="
        height: calc(100% - 140px);
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <h2 style="text-align: center">
        Du har inte behörighet till denna resurs!
      </h2>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
