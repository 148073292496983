export enum TonerColor {
    BLACK = 'black',
    YELLOW = 'yellow',
    CYAN = 'cyan',
    MAGENTA = 'magenta',
}


export const TonerTranslation = {
    [TonerColor.BLACK]: 'Svart',
    [TonerColor.YELLOW]: 'Gul',
    [TonerColor.CYAN]: 'Cyan',
    [TonerColor.MAGENTA]: 'Magenta',
};