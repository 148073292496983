import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: Observable<User>;

  constructor(private db: AngularFirestore) { }

  get User(): Observable<User> {
    if (this.user) {
      return this.user;
    }


    const uid = auth().currentUser.uid;


    this.user = this.db.doc(`users/${uid}`).get().pipe(map(userSnapshot => {
      const userData = Object.assign({}, userSnapshot.data(), {
        id: userSnapshot.id
      });
      const user = new User(userData);
      return user;
    }
    ));



    return this.user;

  }

}
