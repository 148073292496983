import { Component, OnInit } from '@angular/core';
import { PERMISSIONS } from '../models/Permissions';
import { UserService } from '../services/User.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  items: number;
  permissions: typeof PERMISSIONS;

  sidenav: any;

  constructor(public userService: UserService) {
    this.items = 2;
    this.permissions = PERMISSIONS;
  }

  ngOnInit() {
    this.userService.User.subscribe(user => {
      if (user.HasPermission(PERMISSIONS.MANAGE_STATS)) {
        this.items++;
      }
      if (user.HasPermission(PERMISSIONS.MANAGE_TONERS)) {
        this.items++;
      }
    }
    );
  }

}
