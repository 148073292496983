<mat-sidenav-container *ngIf="userService.User | async as User">
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
        <li *ngIf="User.HasPermission(permissions.MANAGE_STATS)">
          <a [routerLink]="['/stats']">Statistik</a>
        </li>
        <li *ngIf="User.HasPermission(permissions.MANAGE_TONERS)">
          <a [routerLink]="['/toners']">Toners</a>
        </li>
        <!-- <li *ngIf="User.HasPermission(permissions.SERVICE)">
          <a [routerLink]="['/services']">Service</a>
        </li> -->
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar [sidenav]="sidenav"></app-header-toolbar>
    </div>
    <div class="content-container">
      <div class="grid">
        <mat-card
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/todo']"
        >
          <h2>RTG TV</h2>
        </mat-card>
        <mat-card
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/tv']"
        >
          <h2>Hantera TV</h2>
        </mat-card>
        <mat-card
          *ngIf="User.HasPermission(permissions.MANAGE_STATS)"
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/stats']"
        >
          <h2>Statistik</h2>
        </mat-card>
        <mat-card
          *ngIf="User.HasPermission(permissions.MANAGE_TONERS)"
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/toners']"
        >
          <h2>Toners</h2>
        </mat-card>
        <mat-card
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/warehouse']"
        >
          <h2>Lagerposition</h2>
        </mat-card>
        <!-- <mat-card
          *ngIf="User.HasPermission(permissions.SERVICE)"
          class="mat-elevation-z8"
          style="padding: 10px 5px !important; cursor: pointer"
          [routerLink]="['/services']"
        >
          <h2>Service</h2>
        </mat-card> -->
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
