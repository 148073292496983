<app-loading-dialog
  [loadingState]="loadingState"
  [messages]="messages"
></app-loading-dialog>
<div
  class="content-container"
  style="
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  "
>
  <mat-card
    class="mat-elevation-z8"
    style="margin-top: -80px; padding: 16px !important"
  >
    <mat-form-field>
      <input
        autocomplete="off"
        matInput
        placeholder="Användarnamn"
        [(ngModel)]="email"
        type="text"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        autocomplete="off"
        (keyup)="passwordKeyup($event)"
        matInput
        placeholder="Lösenord"
        [(ngModel)]="password"
        type="password"
      />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="login()">
      Logga in
    </button>
  </mat-card>
</div>
