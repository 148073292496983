import { DocumentReference } from '@angular/fire/firestore';
import { auth, firestore } from 'firebase';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { HasPermission } from './Permissions';

export class User {

    displayName: string;
    permission: number;
    email: string;
    ref: DocumentReference;
    id: string;

    constructor(user) {
        this.displayName = user.displayName || '';
        this.permission = user.permission || 0;
        this.email = user.email;
        this.ref = user.ref;
        this.id = user.id;
    }
    HasPermission(permission) {
        return HasPermission(this.permission, permission);
    }
}


export const GetCurrentUser = async () => {
    const uid = auth().currentUser.uid;
    const userSnapshot = await firestore().doc(`users/${uid}`).get();
    const userData = Object.assign({}, userSnapshot.data(), {
        ref: userSnapshot.ref,
        id: userSnapshot.id
    });
    return new User(userData);
};

export const GetCurrentUserObservable = () => {
    const uid = auth().currentUser.uid;
    return from(firestore().doc(`users/${uid}`).get()).pipe(map(userSnapshot => {
        const userData = Object.assign({}, userSnapshot.data(), {
            ref: userSnapshot.ref,
            id: userSnapshot.id
        });
        return new User(userData);
    }
    ));
};
