<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <app-loading-dialog
      [loadingState]="loadingState"
      [messages]="messages"
    ></app-loading-dialog>
    <div class="content-container" *ngIf="userService.User | async as User">
      <div
        class="import-container mb30"
        *ngIf="User.HasPermission(Permissions.IMPORT_TV)"
      >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <label for="file" style="flex: 1; cursor: pointer">
            <input
              type="file"
              #file
              id="file"
              accept="text/csv"
              (change)="onFileChange($event)"
            />
            {{ uploadLabelText }}
          </label>
          <button mat-raised-button color="primary" (click)="uploadFile()">
            Ladda upp
          </button>
        </div>
      </div>
      <div
        class="import-container"
        *ngIf="User.HasPermission(Permissions.IMPORT_EAN)"
      >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <label for="eanFile" style="flex: 1; cursor: pointer">
            <input
              type="file"
              #eanFile
              id="eanFile"
              accept="text/csv"
              (change)="onEANFileChange($event)"
            />
            {{ uploadEANLabelText }}
          </label>
          <button mat-raised-button color="primary" (click)="uploadEANFile()">
            Ladda upp
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
