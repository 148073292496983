export enum PERMISSIONS {
    CREATE_TODO = Math.pow(2, 0),
    EDIT_TODO = Math.pow(2, 1),
    DELETE_TODO = Math.pow(2, 2),
    EDIT_TV = Math.pow(2, 3),
    IMPORT_TV = Math.pow(2, 4),
    INVENTORY_TV = Math.pow(2, 5),
    MANAGE_USERS = Math.pow(2, 6),
    MANAGE_SELF = Math.pow(2, 7),
    IMPORT_EAN = Math.pow(2, 8),
    INVENTORY_RTG = Math.pow(2, 9),
    MANAGE_TONERS = Math.pow(2, 10),
    MANAGE_STATS = Math.pow(2, 11),
    SERVICE = Math.pow(2, 12),
}

// tslint:disable-next-line:no-bitwise
export const ADMIN_MASK = PERMISSIONS.CREATE_TODO | PERMISSIONS.EDIT_TODO |
    PERMISSIONS.DELETE_TODO | PERMISSIONS.EDIT_TV |
    PERMISSIONS.IMPORT_TV | PERMISSIONS.INVENTORY_TV |
    PERMISSIONS.MANAGE_USERS | PERMISSIONS.MANAGE_SELF |
    PERMISSIONS.IMPORT_EAN | PERMISSIONS.INVENTORY_RTG |
    PERMISSIONS.MANAGE_TONERS | PERMISSIONS.MANAGE_STATS;

export const HasPermission = (userPermission: number, permission: PERMISSIONS) => {
    // tslint:disable-next-line:no-bitwise
    return Boolean(userPermission & permission);
};
