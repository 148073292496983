import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta } from '@angular/platform-browser';
import { firestore } from 'firebase';

interface Store {
  storeNumber: string; storeName: string;
}

@Component({
  selector: 'app-head-count',
  templateUrl: './head-count.component.html',
  styleUrls: ['./head-count.component.scss'],

})
export class HeadCountComponent implements OnInit {

  storeSelected = false;

  maxCustomers: number;
  headCount: number;
  storeNumber: string;
  storeName: string;

  stores: Store[];
  unsubscribe: () => void;

  constructor(private db: AngularFirestore, private meta: Meta) { }

  ngOnInit() {
    firestore().collection('public').onSnapshot(snapshot => {
      this.stores = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          storeNumber: doc.id,
          storeName: data.storeName
        } as Store;
      }).sort((a, b) => a.storeNumber.localeCompare(b.storeNumber));

    });


  }

  selectStore(storeNumber) {

    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.storeSelected = true;
    this.storeNumber = storeNumber;
    this.initStore();
  }

  changeStore() {
    this.unsubscribe();
    this.storeSelected = false;
  }

  resetStore() {
    if (confirm('Vill du nollställa antalet kunder?')) {
      firestore().collection('public').doc(this.storeNumber).update({ headCount: 0 });
    }
  }

  initStore() {
    this.unsubscribe = firestore().collection('public').doc(this.storeNumber).onSnapshot(snapshot => {
      this.headCount = snapshot.data().headCount;
      this.storeName = snapshot.data().storeName;
      this.maxCustomers = snapshot.data().maxCustomers;
    });
  }


  add() {
    firestore().collection('public').doc(this.storeNumber).update({ headCount: firestore.FieldValue.increment(1) });
  }

  sub() {
    if (this.headCount === 0) {
      return;
    }
    firestore().collection('public').doc(this.storeNumber).update({ headCount: firestore.FieldValue.increment(-1) });
  }

}
