import { Component, OnInit } from '@angular/core';
import { generate } from 'generate-password-browser';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class ToolsComponent implements OnInit {

  password = '';
  clubMember = false;
  purchaseDate = null;
  purchaseDateFormatted = '';
  daysPassed = 0;
  canReturn = false;
  result = '';
  verified = false;

  constructor() { }

  ngOnInit() {
    this.verifyAccess();
  }

  async verifyAccess() {

    const result = await fetch('https://api.ipify.org?format=json');
    const data = await result.json();
    if (environment.production === false) {
      this.verified = true;
      return;
    }
    if (data.ip === '194.248.0.190') {
      this.verified = true;
      return;
    }
    // Fallback
    const password = prompt('Lösenord');
    if (password === 'elgig2056') {
      this.verified = true;
    }

  }

  generatePassword() {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
    // for (let i = 0, n = charset.length; i < length; ++i) {
    //   password += charset.charAt(Math.floor(Math.random() * n));
    // }
    password = generate({ length: 10, uppercase: true, numbers: true, strict: true, excludeSimilarCharacters: true });
    this.password = password;
    navigator.clipboard.writeText(this.password);
  }
  onDateChange(event) {
    this.purchaseDate = event.value;
    this.purchaseDateFormatted = event.value.format('YYYY-MM-DD');
    this.calculateOpenPurchase();
  }
  calculateOpenPurchase() {
    if (this.purchaseDate === null) {
      return;
    }
    const now = moment();
    this.daysPassed = now.diff(this.purchaseDate, 'days');
    console.log(this.daysPassed);
    this.canReturn = false;
    if (this.clubMember) {
      if (this.daysPassed <= 50) {
        this.canReturn = true;
      }
    } else {
      if (this.daysPassed <= 30) {
        this.canReturn = true;
      }
    }
    this.result = `Kund har
  ${this.clubMember ? ' 50' : ' 30'} dagar öppet köp <br/>
  Det har passerat ${this.daysPassed} dagar och öppet köp ${this.canReturn ? 'gäller' : 'gäller inte'}`;
  }

}
