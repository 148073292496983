import { Component, Input, NgModule, OnInit } from '@angular/core';
import { firestore } from 'firebase';



@Component({
  selector: 'app-warehouse-location',
  templateUrl: './warehouse-location.component.html',
  styleUrls: ['./warehouse-location.component.scss']
})
export class WarehouseLocationComponent implements OnInit {

  @Input() locationLabel: string;

  @Input() matrix: number[][];

  constructor() { }

  ngOnInit() {

  }

  async onDelete(label: string) {

    let target = label;

    const locationParsed = parseInt(label.substr(1), 10);
    const columnLetter = label.substr(0, 1);

    const column = this.matrix.find(columns => {
      return columns.find(row => row === locationParsed);
    });

    const indexInColumn = column.findIndex(row => row === locationParsed);

    let topIndex = 0;
    const bottomIndex = indexInColumn - 1;

    for (let i = bottomIndex; i > 0 - 1; i--) {
      if (column[i] <= 0) {
        if (i === bottomIndex) {
          topIndex = bottomIndex;
        }
        break;
      } else {
        topIndex = i;
      }
    }


    // Loop from bottomIndex to topIndex.
    // Move each element down one position

    for (let i = bottomIndex; i >= topIndex; i--) {
      console.log(i, `${columnLetter}${column[i]}`);

      const elementIDToMove = `${columnLetter}${column[i]}`;
      const elementDocToMove = await firestore().collection('warehouse').doc(elementIDToMove).get();

      if (!elementDocToMove.exists) {
        continue;
      }

      const elementToMove = elementDocToMove.data().product;
      await firestore().collection('warehouse').doc(target).update({ product: elementToMove });

      target = elementIDToMove;

      if (i === topIndex) {
        await firestore().collection('warehouse').doc(target).update({ product: null });
      }
    }


  }

}
