import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-ring',
  templateUrl: './progress-ring.component.html',
  styleUrls: ['./progress-ring.component.scss']
})
export class ProgressRingComponent implements OnInit {


  normalizedRadius = 0;
  circumference = 0;

  @Input()
  label = '';
  @Input()
  amount = 0;
  @Input()
  radius: number;
  @Input()
  stroke: number;
  @Input()
  progress: number;

  constructor() { }

  ngOnInit() {
    this.normalizedRadius = this.radius - this.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

}
