import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import * as moment from 'moment';

@Component({
  selector: 'app-new-todo',
  templateUrl: './new-todo.component.html',
  styleUrls: ['./new-todo.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [
        MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS
      ]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class NewTodoComponent implements OnInit {

  deadline = new FormControl(new Date());

  displayedColumns = ['sku', 'model', 'goalAmount', 'premium', 'actions'];
  selectedItem: any;
  loading = false;
  activeSKU = null;
  title = '';
  items = [];

  sku: string;
  model: string;
  goalAmount: string;

  isEditing = () => !!this.selectedItem;

  constructor(private db: AngularFirestore, private auth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
  }

  async searchSKU() {
    if (this.sku) {
      this.activeSKU = null;
      this.model = '';
      const sku = await this.db.doc(`skus/${this.sku}`).get();
      const skuSnapshot = await sku.toPromise();
      if (skuSnapshot.exists) {
        const todo = skuSnapshot.data();
        this.activeSKU = todo;
        this.model = todo.model;
      }
    }

  }

  onDeadlineChange(event) {
    this.deadline = new FormControl(event.value.toDate());
  }

  getTotalTodo() {
    return this.items.reduce((a, c) => a + c.goalAmount, 0);
  }

  removeItem(item) {
    this.items = this.items.filter(ti => ti.sku !== item.sku);
    this.sku = '';
    this.model = '';
    this.goalAmount = '';
    this.selectedItem = null;
  }

  onEdit(item) {
    this.sku = item.sku;
    this.model = item.model;
    this.goalAmount = item.goalAmount.toString();
    this.selectedItem = item;
    this.activeSKU = this.selectedItem.meta;
  }

  onAdd() {
    const goalAmount = parseInt(this.goalAmount, 10);
    if (isNaN(goalAmount) || goalAmount < 0) {
      alert('Valt antal är ogiltigt');
      return;
    }
    if (this.isEditing()) {
      this.selectedItem.sku = this.sku;
      this.selectedItem.model = this.model;
      this.selectedItem.goalAmount = parseInt(this.goalAmount, 10);
      // tslint:disable-next-line:max-line-length
      this.selectedItem.newMeta = Object.assign({}, this.selectedItem.meta, { stock: { rtg: this.selectedItem.meta.stock.rtg + parseInt(this.goalAmount, 10), available: this.selectedItem.meta.stock.available } });
    } else {
      const todo = this.items.find(t => t.sku === this.sku);
      if (todo) {
        alert(`${this.sku} finns redan i listan`);
        return;
      }
      const item = {
        sku: this.sku,
        model: this.model,
        goalAmount: parseInt(this.goalAmount, 10),
        comment: '',
        meta: {
          model: this.model,
          sku: this.sku,
          cg: { c: 2, g: 24 },
          premium: false,
          stock: { rtg: 0, available: 0 }
        },
        newMeta: {
          model: this.model,
          sku: this.sku,
          cg: { c: 2, g: 24 },
          premium: false,
          stock: { rtg: parseInt(this.goalAmount, 10), available: 0 }
        }
      };
      if (this.activeSKU) {
        item.meta = this.activeSKU;
        // tslint:disable-next-line:max-line-length
        item.newMeta = Object.assign({}, this.activeSKU, { stock: { rtg: this.activeSKU.stock.rtg + parseInt(this.goalAmount, 10), available: this.activeSKU.stock.available } });
      }
      this.items.push(item);
      this.items = [...this.items];
    }
    this.sku = '';
    this.model = '';
    this.goalAmount = '';
    this.selectedItem = null;
    this.activeSKU = null;
  }

  async onSubmit() {

    this.title = this.title.trim();
    const errors: { [title: string]: { message: string } } = {};
    if (this.title.length < 2) {
      errors.title = { message: 'Titel sakas' };
    }
    if (this.items.length === 0) {
      errors.items = { message: 'Listan saknar produkter' };
    }
    if (Object.keys(errors).length > 0) {
      const messages = Object.keys(errors).map(key => errors[key].message);
      alert(messages.join('\n'));
      return;
    }
    this.loading = true;
    const deadline = this.deadline.value;
    if (moment(deadline).isSame(moment(), 'day')) {
      if (confirm('Deadline är satt till idag, stämmer det?') === false) {
        this.loading = false;
        return;
      }
    }
    const uid = this.auth.auth.currentUser.uid;
    const userRef = this.db.doc(`users/${uid}`).ref;
    const todoRef = await this.db.collection('todo').add({
      title: this.title,
      deadline,
      createdBy: userRef,
      progress: 0,
      goalAmount: this.getTotalTodo(),
      doneAmount: 0
    });
    const dbSkus = (await firestore().collection('skus').get()).docs.map(doc => (Object.assign({}, doc.data())));
    const skusToInsert = this.items.filter(item => {
      const sku = dbSkus.find(dbSku => dbSku.sku === item.sku);
      if (sku) {
        return false;
      } else {
        return true;
      }
    });
    // tslint:disable-next-line:max-line-length
    const skuInsertions = skusToInsert.map(item => this.db.doc(`skus/${item.sku}`).set({ sku: item.sku, model: item.model, stock: { available: 0, rtg: 0 }, cg: { c: 2, g: 24 } }));
    await Promise.all(skuInsertions);
    const itemInsertions = this.items.map(item => {
      item.ref = this.db.doc(`skus/${item.sku}`).ref;
      item.doneAmount = 0;
      return todoRef.collection('items').add(item);
    });
    await Promise.all(itemInsertions);
    this.loading = false;
    this.router.navigateByUrl(`/todo/${todoRef.id}`);

  }

}
