<div
  *ngIf="storeSelected == false"
  style="
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  "
>
  <h1 style="color: white; margin-top: 0">Välj butik</h1>
  <div
    style="
      background-color: #424242;
      color: white;
      width: 90vw;
      height: 80vh;
      max-width: 800px;
      overflow-y: auto;
      overflow-x: hidden;
    "
  >
    <div
      *ngFor="let store of stores"
      style="
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid #797878;
        margin: 10px;
      "
      (click)="selectStore(store.storeNumber)"
    >
      <span style="font-size: 1.3rem">{{ store.storeName }}</span>
      <span>{{ store.storeNumber }}</span>
    </div>
  </div>
</div>

<div
  *ngIf="storeSelected"
  style="
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
  "
>
  <div
    style="
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
      flex-direction: column;
    "
  >
    <span style="color: white; margin-bottom: 30px">{{ storeName }}</span>
    <div>
      <span style="color: white; font-size: 8rem">{{ headCount }}</span>
      <span style="color: white; font-size: 4rem">/</span>
      <span style="color: white; font-size: 4rem">{{ maxCustomers }}</span>
    </div>

    <span style="color: white; margin-top: 30px"
      >Tillgängliga platser: {{ maxCustomers - headCount }}</span
    >
  </div>

  <div style="display: flex; height: 30vh">
    <div
      (click)="sub()"
      style="
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #f44336;
        color: white;
        font-size: 10rem;
        cursor: pointer;
        user-select: none;
      "
    >
      <span>-</span>
    </div>
    <div
      (click)="add()"
      style="
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #185635;
        color: white;
        font-size: 10rem;
        cursor: pointer;
        user-select: none;
      "
    >
      <span>+</span>
    </div>
  </div>
</div>

<div
  *ngIf="storeSelected"
  style="position: absolute; top: 15px; left: 15px"
  (click)="changeStore()"
>
  <span style="color: white; user-select: none; cursor: pointer"
    >Välj butik</span
  >
</div>

<div
  *ngIf="storeSelected"
  style="position: absolute; top: 15px; right: 15px"
  (click)="resetStore()"
>
  <span style="color: white; user-select: none; cursor: pointer"
    >Nollställ</span
  >
</div>
