<mat-toolbar class="mat-elevation-z2" *ngIf="userService.User | async as User">
  <mat-toolbar-row>
    <button *ngIf="sidenav" (click)="sidenav.toggle()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <button *ngIf="showBack" id="back" (click)="goBack()" mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <label for="back" *ngIf="showBack">{{ title }}</label>
    <span style="flex: 1"></span>
    <span
      style="margin-right: 20px"
      [routerLink]="
        User.HasPermission(Permissions.MANAGE_SELF) ? ['/manage-self'] : []
      "
    >
      {{ User.displayName }}
    </span>
    <button
      *ngIf="User.HasPermission(Permissions.MANAGE_USERS)"
      style="margin-right: 20px"
      [routerLink]="['/manage-users']"
      mat-icon-button
    >
      <mat-icon>supervisor_account</mat-icon>
    </button>
    <button [routerLink]="['/signout']" mat-icon-button>
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-toolbar-row></mat-toolbar
>
