<div class="content-container" style="padding-top: 30px" *ngIf="verified">
  <mat-card class="mat-elevation-z2 mb30">
    <div style="padding: 10px">
      <h2 style="margin-top: 0">Password generator</h2>
      <mat-form-field appearance="fill">
        <mat-label>Lösenord</mat-label>
        <input
          disabled
          autocomplete="off"
          [(ngModel)]="password"
          matInput
          type="text"
        />
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="generatePassword()">
        Slumpa och kopiera lösenord
      </button>
    </div>
  </mat-card>
  <mat-card class="mat-elevation-z2 mb30">
    <div style="padding: 10px">
      <h2 style="margin-top: 0">Öppet köp kontroll</h2>
      <mat-checkbox [(ngModel)]="clubMember" (change)="calculateOpenPurchase()"
        >Klubbmedlem</mat-checkbox
      >
      <div>
        <p style="cursor: pointer" (click)="picker.open()">
          Köpdatum: {{ purchaseDateFormatted }}
        </p>
        <div
          *ngIf="result.length > 0"
          [ngStyle]="{ 'background-color': canReturn ? '#206040' : '#f44336' }"
          style="padding: 20px; text-align: center"
        >
          <p
            style="line-height: 25px; font-size: larger"
            [innerHTML]="result"
          ></p>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<div [hidden]="true">
  <mat-form-field appearance="fill">
    <mat-label>Köpdatum</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      disabled
      autocomplete="off"
      (dateChange)="onDateChange($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      touchUi
      disabled="false"
      startView="month"
    ></mat-datepicker>
  </mat-form-field>
</div>
