import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { firestore } from 'firebase';
import { LoadingState } from 'src/app/components/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-tv-inventory',
  templateUrl: './tv-inventory.component.html',
  styleUrls: ['./tv-inventory.component.scss']
})
export class TvInventoryComponent implements OnInit {

  loadingState = LoadingState.IDLE;
  messages = {
    [LoadingState.WORKING]: 'Uppdaterar resultat',
    [LoadingState.DONE]: 'Uppdaterat'
  };
  counted = [];
  allSkus = [];
  selectedSKU = null;
  loaded = false;
  ean = '';
  amount = '';
  itemDescription = '';
  displayedColumns = ['sku', 'model', 'rtg'];

  constructor(private location: Location) { }

  ngOnInit() {
    this.initSKUs();
  }

  async initSKUs() {

    this.allSkus = (await firestore().collection('skus').get())
      .docs.map(doc => (Object.assign({}, doc.data(), { ref: doc.ref, id: doc.id })));
    this.loaded = true;

  }

  onSubmitEAN($event) {
    console.log('TvInventoryComponent -> onSubmitEAN -> $event', $event);
    if (this.ean.length > 5 && $event.key === 'Enter') {
      this.searchSKU();
      // setTimeout(() => {
      //   this.searchSKU();
      // }, 500);
    }
  }
  onSubmitAmount($event) {
    if (this.selectedSKU !== null && $event.key === 'Enter') {
      const countedSku = this.counted.find(s => s.sku === this.selectedSKU.sku);
      countedSku.stock.rtg = parseInt(this.amount, 10);
      this.selectedSKU = null;
      this.ean = '';
      this.amount = '';
      this.itemDescription = countedSku.model;
      this.counted = [...this.counted];
    }
  }

  async searchSKU() {

    const sku = this.allSkus.find(s => s.ean === this.ean);
    if (sku) {
      const countedSku = this.counted.find(s => s.sku === sku.sku);
      if (countedSku) {
        countedSku.stock.rtg++;
      } else {
        sku.stock.rtg = 1;
        this.counted.push(sku);
      }
      this.selectedSKU = null;
      this.ean = '';
      this.amount = '';
      this.itemDescription = sku.model;
      this.counted = [...this.counted];
    } else {
      // alert('Okänt EAN');
      window.navigator.vibrate([500, 50, 500]);
      this.ean = '';
    }
  }

  selectSKU(sku) {
    this.selectedSKU = sku;
    this.ean = sku.ean;
    this.amount = sku.stock.rtg.toString();
    this.itemDescription = sku.model;
    console.log('Selected', sku);
  }

  submit() {
    if (this.counted.length === 0) {
      alert('Saknar linjer');
      return;
    }
    if (confirm('Spara resultat?')) {
      this.saveResult();
    }
  }

  async saveResult() {

    this.loadingState = LoadingState.WORKING;
    const update = this.allSkus.map(sku => {
      const countedSku = this.counted.find(s => s.sku === sku.sku);
      if (countedSku) {
        return countedSku.ref.update({
          'stock.rtg': countedSku.stock.rtg
        });
      } else {
        return sku.ref.update({
          'stock.rtg': 0
        });
      }
    });
    await Promise.all(update);
    this.loadingState = LoadingState.DONE;
    await this.sleep(2000);
    this.loadingState = LoadingState.IDLE;
    this.location.back();

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
