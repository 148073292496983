import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import * as PDFJS from 'pdfjs-dist/es5/build/pdf';
import { PDFDocumentLoadingTask, PDFDocumentProxy } from 'pdfjs-dist/types/display/api';
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
import { saveAs } from 'file-saver';

import * as moment from 'moment';
import { LoadingState } from '../components/loading-dialog/loading-dialog.component';

export interface ProductPrototype {
    sku: string;
    description: string;
}

export interface SKU extends ProductPrototype {
    cgm: number;
}


export interface Product extends ProductPrototype {
    quantity: number;
    cgm: number;
}

declare global {
    interface File {
        arrayBuffer: () => Promise<ArrayBuffer>;
        text: () => Promise<string>;
    }
}



@Component({
    selector: 'app-bunker',
    templateUrl: './bunker.component.html',
    styleUrls: ['./bunker.component.scss']
})
export class BunkerComponent implements OnInit {

    @ViewChild('packingnoteInput', { static: false }) packingnoteInput: ElementRef<HTMLInputElement>;
    @ViewChild('cgmInput', { static: false }) cgmInput: ElementRef<HTMLInputElement>;

    packingnoteInputLabel = 'Ingen fil har valts (.pdf)';
    cgmInputLabel = 'Inga filer har valts (.csv)';

    products: Product[] = [];
    skusToDB: SKU[] = [];
    skusFromDB: SKU[] = [];

    loadingState = LoadingState.IDLE;
    messages = {
        [LoadingState.WORKING]: 'WORKING'
    };


    validated = false;

    constructor(private db: AngularFirestore) { }

    keys: { [key: string]: boolean };

    ngOnInit() {
        firestore().collection('bunker').onSnapshot(bunkerSnapshot => {
            this.skusFromDB = bunkerSnapshot.docs.map(doc => ({ ...doc.data() as SKU }));
            console.log('this.skusFromDB', this.skusFromDB);
        });

        document.addEventListener('keydown', (event) => {
            this.keys = (this.keys || {});

            this.keys[event.key] = true;
            if (this.keys.Control && this.keys.Alt && this.keys.F5) {
                this.validated = true;

                setTimeout(() => {
                    this.validated = false;
                }, 1 * 60 * 1000);
            }

        });

        document.addEventListener('keyup', event => {
            this.keys[event.key] = false;
        });

    }



    onFileChange($event) {
        if ($event.target.files.length === 1) {
            this.packingnoteInputLabel = $event.target.files[0].name;
        } else {
            this.packingnoteInputLabel = 'Ingen fil har valts (.pdf)';
        }
    }

    onCGM($event) {
        if ($event.target.files.length > 0) {
            const fileList = $event.target.files as FileList;
            console.log(fileList);
            let label = '';
            for (let index = 0; index < fileList.length; index++) {
                const file = fileList.item(index);

                label += `${file.name}`;

                if (index < fileList.length - 1) {
                    label += ', ';
                }
            }
            this.cgmInputLabel = label;
        } else {
            this.cgmInputLabel = 'Inga filer har valts (.csv)';
        }
    }

    async uploadPackingnote() {
        const fileList = this.packingnoteInput.nativeElement.files as FileList;

        if (fileList.length === 0) {
            alert('Ingen fil vald');
            return;
        }

        this.loadingState = LoadingState.WORKING;


        const file = fileList.item(0);
        PDFJS.GlobalWorkerOptions.workerSrc = await pdfjsWorker;

        const document = PDFJS.getDocument(await file.arrayBuffer()) as PDFDocumentLoadingTask;

        const pdf = await document.promise;

        const total = pdf.numPages;

        const promises: Promise<void>[] = [];

        for (let i = 1; i <= total; i++) {
            promises.push(this.parsePage(pdf, i));
        }

        await Promise.all(promises);

        console.log(this.products);

        this.generateCSVFile();

    }

    async parsePage(pdf: PDFDocumentProxy, pageNumber: number) {
        const page = await pdf.getPage(pageNumber);

        const textContent = await page.getTextContent();

        const patt = new RegExp(/([A-Z0-9]){4,}/);

        for (const [index, item] of textContent.items.entries()) {
            let isS2S = false;


            if (item.str.length >= 4 &&
                item.str.indexOf(' ') < 0 &&
                item.str.indexOf(',') < 0 &&
                item.str.indexOf('-') < 0 &&
                item.str.indexOf('/') < 0 &&
                item.str.toUpperCase() === item.str &&
                patt.test(item.str) &&
                (
                    (textContent.items[index + 2].str.indexOf('/') >= 0 &&
                        parseInt(textContent.items[index + 3].str, 10) === parseInt(textContent.items[index + 3].str, 10)
                        // textContent.items[index + 3].str * 1 == textContent.items[index + 3].str
                    )
                    || (textContent.items[index + 2].str.indexOf('/') < 0 &&
                        // textContent.items[index + 2].str * 1 == textContent.items[index + 2].str
                        parseInt(textContent.items[index + 2].str, 10) === parseInt(textContent.items[index + 2].str, 10)
                    )
                )
            ) {



                if (textContent.items[index + 2].str.indexOf('/') >= 0 &&
                    parseInt(textContent.items[index + 3].str, 10) === parseInt(textContent.items[index + 3].str, 10)
                    // textContent.items[index + 3].str * 1 == textContent.items[index + 3].str
                ) {
                    isS2S = true;
                } else if (textContent.items[index + 2].str.indexOf('/') < 0 &&
                    parseInt(textContent.items[index + 2].str, 10) === parseInt(textContent.items[index + 2].str, 10)
                    // textContent.items[index + 2].str * 1 == textContent.items[index + 2].str
                ) {
                    isS2S = false;
                }

                let quantity = Number.MIN_VALUE;

                if (isS2S) {
                    if (parseInt(textContent.items[index + 3].str, 10) > 1000) {
                        continue;
                    } else {
                        quantity = parseInt(textContent.items[index + 3].str, 10);
                    }
                } else {
                    if (parseInt(textContent.items[index + 2].str, 10) > 1000) {
                        continue;
                    } else {
                        quantity = parseInt(textContent.items[index + 2].str, 10);
                    }
                }

                const sku = item.str;
                const description = textContent.items[index + 1].str;

                this.addProduct(sku, description, quantity);


            }

        }

    }

    addProduct(sku: string, description: string, quantity: number) {

        const product = this.products.find(p => p.sku === sku);
        if (product) {
            product.quantity += quantity;
        } else {
            this.products.push({ sku, description, quantity, cgm: this.cgmLookup(sku) });
        }
    }

    cgmLookup(sku: string) {
        const product = this.skusFromDB.find(s => s.sku === sku);
        if (product) {
            return product.cgm;
        }
        return 0;
    }


    async parseCGM() {
        this.loadingState = LoadingState.WORKING;
        const fileList = this.cgmInput.nativeElement.files;
        const promises: Promise<void>[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);
            promises.push(this.parseCGMFile(file));
        }

        await Promise.all(promises);

        await this.updateDB();


        this.cgmInputLabel = 'Inga filer har valts (.csv)';
        this.cgmInput.nativeElement.value = '';
        this.loadingState = LoadingState.IDLE;


        console.log(this.skusToDB);
    }

    updateDB = async () => {
        let uploadCount = 0;
        const promises: Promise<void>[] = [];
        for (const sku of this.skusToDB) {
            const skuFromDB = this.skusFromDB.find(s => s.sku === sku.sku);
            if (skuFromDB) {
                continue;
            }

            promises.push(firestore().collection('bunker')
                .doc(sku.sku).set(sku));

            uploadCount++;
        }

        await Promise.all(promises);

        console.log(`${uploadCount} SKUs uploaded to DB`);

        alert(`${uploadCount} SKUs uploaded to DB`);
    }


    async parseCGMFile(file: File) {
        const text = await file.text();
        const lines = text.split('\n');

        const cgm: number = parseInt(file.name.replace('.csv', ''), 10);

        for (const line of lines) {
            const parts = line.split(';');
            if (parts[0] !== 'A') {
                continue;
            }

            if (parts[3].length < 4) {
                continue;
            }

            const sku = parts[1];
            const description = parts[3];

            this.skusToDB.push({ sku, description, cgm });
        }
    }

    // <li>431 Mobile phones</li>
    // <li>440 Watches</li>
    // <li>519 Laptop Apple</li>
    // <li>520 Laptop Chromebooks</li>
    // <li>521 Laptop Commercial</li>
    // <li>522 Laptop Gaming</li>
    // <li>525 Tablet Apple</li>
    // <li>526 Desktop Apple</li>
    // <li>533 Laptop Windows</li>
    // <li>534 Tablet Android</li>

    generateCSVFile() {
        const products = this.products.filter(p => {
            switch (p.cgm) {
                case 431:
                case 440:
                case 519:
                case 520:
                case 521:
                case 522:
                case 525:
                case 526:
                case 533:
                case 534:
                    return true;
                default:
                    return false;
            }
        });

        const week = moment().week();


        let text = '';
        for (const product of products) {
            for (let index = 0; index < product.quantity; index++) {
                text += `${product.sku};${product.description};${week}\n`;
            }
        }
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'bunker.csv');



        this.packingnoteInputLabel = 'Ingen fil har valts (.pdf)';
        this.packingnoteInput.nativeElement.value = '';
        this.loadingState = LoadingState.IDLE;

    }
}
