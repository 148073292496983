<div style="display: flex; align-items: center">
  <h1 mat-dialog-title style="margin: 0; flex: 1">
    {{ data.item.sku }} - {{ data.item.model }}
  </h1>
  <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<div
  mat-dialog-content
  style="display: flex; flex-direction: column; height: 100%; max-height: 75vh"
>
  <div
    style="
      display: flex;
      flex-direction: column-reverse;
      overflow: hidden;
      overflow-y: scroll;
    "
  >
    <div
      *ngFor="let comment of comments$ | async"
      class="comment-wrapper"
      [ngClass]="{
        'self-comment': (userId$ | async) === comment.commentBy.ref.id
      }"
    >
      <div class="comment-body mat-elevation-z4">{{ comment.comment }}</div>
      <p class="comment-author">{{ comment.commentBy.displayName }}</p>
      <p class="comment-time">
        <small>{{ getTime(comment) }}</small>
      </p>
    </div>
  </div>
  <div style="flex: 1"></div>
  <div style="display: flex; align-items: baseline">
    <mat-form-field>
      <textarea
        matInput
        [(ngModel)]="comment"
        rows="3"
        placeholder="Skriv din kommentar här"
      ></textarea>
    </mat-form-field>
    <button mat-icon-button (click)="addComment()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-actions></div>
