import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';
import { UserService } from '../services/User.service';

@Component({
  selector: 'app-manage-self',
  templateUrl: './manage-self.component.html',
  styleUrls: ['./manage-self.component.scss']
})
export class ManageSelfComponent implements OnInit {

  displayName = '';
  password = '';
  confPassword = '';
  currentPassword = '';

  constructor(public userService: UserService, private afAuth: AngularFireAuth, private db: AngularFirestore, private location: Location) {

  }

  ngOnInit() {
    this.userService.User.toPromise().then(user => {
      this.displayName = user.displayName;
    });
  }

  async submit() {

    if (this.displayName !== this.afAuth.auth.currentUser.displayName) {
      await this.afAuth.auth.currentUser.updateProfile({ displayName: this.displayName });
      await this.db.doc(`users/${this.afAuth.auth.currentUser.uid}`).update({ displayName: this.displayName });
    }
    if (this.password.length >= 6 && this.confPassword.length >= 6) {
      if (this.password === this.confPassword) {
        const cred = auth.EmailAuthProvider.credential(this.afAuth.auth.currentUser.email, this.currentPassword);
        const userCred = await this.afAuth.auth.currentUser.reauthenticateWithCredential(cred);
        userCred.user.updatePassword(this.password);
      }
    }
    window.location.reload();

  }

}
