import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PERMISSIONS } from 'src/app/models/Permissions';
import { UserService } from 'src/app/services/User.service';

@Component({
  selector: 'app-tv-listing',
  templateUrl: './tv-listing.component.html',
  styleUrls: ['./tv-listing.component.scss']
})
export class TvListingComponent implements OnInit {

  displayedColumns = ['sku', 'model', 'stock', 'hit', 'premium', 'actions'];
  Permissions = PERMISSIONS;

  term = new FormControl('');
  term$: Observable<string>;

  premium = new FormControl(true);
  premium$: Observable<boolean>;

  tv$: Observable<any>;
  filteredTvs$: Observable<any>;


  constructor(private db: AngularFirestore, public userService: UserService) {


    this.term$ = this.term.valueChanges.pipe(startWith(''));
    this.premium$ = this.premium.valueChanges.pipe(startWith(true));
    this.tv$ = this.db.collection('skus').snapshotChanges()
      .pipe(map(actions => {
        const result = actions
          // .filter(({ payload }) => {
          //   const cg = payload.doc.data().cg;
          //   if (!cg) {
          //     return false;
          //   }
          //   return JSON.stringify(cg) === JSON.stringify(FILTER_CG);
          // })
          .map(({ payload }) => {
            let hit = 0;
            // tslint:disable-next-line:max-line-length
            const sku = Object.assign({ stock: { rtg: 0, available: 0 } }, payload.doc.data(), { id: payload.doc.id, ref: payload.doc.ref, hit });
            if (sku.stock.available > 0) {
              hit = Math.round((sku.stock.rtg / sku.stock.available) * 100);
              sku.hit = hit;
            }
            return sku;
          }).sort((a: any, b: any) => a.sku.localeCompare(b.sku));
        return [...result.filter((s: any) => s.premium), ...result.filter((s: any) => s.premium === undefined || s.premium === false)];
      }));
    this.filteredTvs$ = combineLatest(this.tv$, this.term$, this.premium$)
      .pipe(map(([skus, filterString, premium]) => {
        if (filterString.length >= 2) {
          return skus.filter(s => {
            return s.sku.toLocaleLowerCase().includes(filterString.toLocaleLowerCase())
              || s.model.toLocaleLowerCase().includes(filterString.toLocaleLowerCase());
          });
        }
        if (premium) {
          return skus.filter(sku => sku.premium);
        }
        return skus;
      }));
  }

  ngOnInit() {
  }

}
