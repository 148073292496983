import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatInput } from '@angular/material/input';

import { firestore } from 'firebase';

interface WarehouseLocations {
  product: string;
}

@Component({
  selector: 'app-warehouse-position',
  templateUrl: './warehouse-position.component.html',
  styleUrls: ['./warehouse-position.component.scss']
})
export class WarehousePositionComponent implements OnInit {

  @Input()
  label: string;

  @Output()
  deleteEvent = new EventEmitter<string>();

  product: string;
  productInput: string;

  @ViewChild('productInputRef', { read: MatInput }) productInputRef: MatInput;

  docExists = false;

  modalVisible = false;

  constructor() { }

  ngOnInit() {
    firestore().collection('warehouse').doc(this.label).onSnapshot(docSnapshot => {
      this.docExists = docSnapshot.exists;
      if (docSnapshot.exists) {
        const docData = docSnapshot.data() as WarehouseLocations;
        this.product = docData.product || '';
      } else {
        this.product = '';
      }

      this.product = this.product.toLocaleUpperCase();
      this.productInput = this.product;
    });
  }

  onClick() {
    this.productInput = this.product;
    this.modalVisible = true;
    setTimeout(() => this.productInputRef.focus(), 0);

  }

  dismiss() {
    this.modalVisible = false;
  }

  save() {
    this.modalVisible = false;

    if (this.docExists) {
      firestore().collection('warehouse').doc(this.label).update({ product: this.productInput });
    } else {
      firestore().collection('warehouse').doc(this.label).set({ product: this.productInput });
    }

  }

  delete() {
    this.modalVisible = false;
    firestore().collection('warehouse').doc(this.label).set({ product: null });
    this.deleteEvent.emit(this.label);
  }

}
