import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { PERMISSIONS } from 'src/app/models/Permissions';
import { UserService } from 'src/app/services/User.service';
import * as moment from 'moment';
import { auth, firestore } from 'firebase';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { LogStat, StatType } from 'src/app/models/LogStat';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss'],
})
export class TodoComponent implements OnInit {

  displayedColumns = ['sku', 'model', 'amount', 'premium', 'actions'];
  Permissions = PERMISSIONS;

  todo$: any;
  todoId: string;

  todoItems$: any;
  total$: any;

  constructor(private auth: AngularFireAuth, private db: AngularFirestore,
    private route: ActivatedRoute, private dialog: MatDialog, public userService: UserService
  ) { }

  async ngOnInit() {

    this.todo$ = this.route.paramMap.pipe(switchMap(paramMap => {
      this.todoId = paramMap.get('id');
      return this.db
        .doc(`todo/${this.todoId}`)
        .snapshotChanges()
        .pipe(map(({ payload }) => {
          const todo: any = payload.data();
          const ref = payload.ref;
          const timestamp = todo.deadline;
          const deadline = moment(timestamp.toDate()).format('YYYY-MM-DD');
          todo.deadline = deadline;
          return Object.assign({}, todo, { ref, id: this.todoId });
        }));
    }));


    this.todoItems$ = this.todo$.pipe(switchMap((todo: any) => {
      const o = this.db
        .doc(`todo/${todo.id}`)
        .collection('items')
        .snapshotChanges()
        .pipe(map(docs => docs
          .map(({ payload }) => {
            const todoItem = payload.doc.data();
            const id = payload.doc.id;
            const ref = payload.doc.ref;
            return Object.assign({}, todoItem, { id, ref });
          })
          .sort((a, b) => this.sortListByProgress(a, b))), docs => {
            return docs.pipe(map(d => d.map(s => (Object.assign({}, s, { realSku: this.db.doc(`skus/${s.sku}`).valueChanges() })))));
          });
      return o;
    }));

    this.todoItems$.subscribe(items => {
      const doneAmount = items.reduce((a, c) => a + c.doneAmount, 0);
      const goalAmount = items.reduce((a, c) => a + c.goalAmount, 0);
      this.db.doc(`todo/${this.todoId}`).update({
        doneAmount,
        goalAmount,
        progress: (doneAmount / goalAmount) * 100
      });
    });


    this.total$ = this.todoItems$.pipe(map((items: any) => {
      const doneAmount = items.reduce((a, c) => a + c.doneAmount, 0);
      const goalAmount = items.reduce((a, c) => a + c.goalAmount, 0);
      return { doneAmount, goalAmount };
    }));


  }

  async add(item) {

    item.ref.update({
      doneAmount: firestore.FieldValue.increment(1)
    });
    this.db.doc(`skus/${item.sku}`).update({
      'stock.rtg': firestore.FieldValue.increment(1)
    });
    if (item.doneAmount + 1 <= item.goalAmount) {
      this.db.doc(`todo/${this.todoId}`).ref.update({
        doneAmount: firestore.FieldValue.increment(1)
      });
    }
    const user = (await firestore().collection('users').doc(auth().currentUser.uid).get()).data();
    const todo = (await firestore().collection('todo').doc(this.todoId).get()).data();


    LogStat(StatType.TV_ADD);

  }
  async sub(item) {

    item.ref.update({
      doneAmount: firestore.FieldValue.increment(-1)
    });
    this.db.doc(`skus/${item.sku}`).update({
      'stock.rtg': firestore.FieldValue.increment(-1)
    });
    if (item.doneAmount - 1 < item.goalAmount) {
      this.db.doc(`todo/${this.todoId}`).ref.update({
        doneAmount: firestore.FieldValue.increment(-1)
      });
    }
    const user = (await firestore().collection('users').doc(auth().currentUser.uid).get()).data();
    const todo = (await firestore().collection('todo').doc(this.todoId).get()).data();

    LogStat(StatType.TV_SUB);
  }

  getProgress(doneAmount, goalAmount) {
    return (doneAmount / goalAmount) * 100;
  }
  sortListByProgress(a, b) {
    const aDone = this.isItemDone(a);
    const bDone = this.isItemDone(b);
    if (aDone && bDone) {
      return 0;
    }
    if (aDone) {
      return 1;
    }
    if (bDone) {
      return -1;
    }
  }
  isItemDone(item) {
    return item.doneAmount >= item.goalAmount;
  }

  addComment(item) {
    console.log(item);
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '95vw',
      maxWidth: '800px',
      height: '95vh',
      data: { item }
    });
    dialogRef.afterClosed().subscribe(async (comment) => {
      if (typeof comment === 'string' && comment.length > 0) {
        const uid = this.auth.auth.currentUser.uid;
        const userDoc = await this.db.doc(`users/${uid}`).get().toPromise();
        const user = userDoc.data();
        user.ref = userDoc.ref;
        user.id = userDoc.id;
        const c = {
          comment,
          timestamp: firestore.Timestamp.now(),
          commentBy: {
            displayName: user.displayName,
            ref: user.ref
          }
        };
        item.ref.update({
          comments: firestore.FieldValue.arrayUnion(c)
        });
      }
    });
  }

}
