import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { firestore, functions } from 'firebase';
import { LoadingState } from '../components/loading-dialog/loading-dialog.component';
import { HasPermission, PERMISSIONS } from '../models/Permissions';
import { User } from '../models/User';
import { UserService } from '../services/User.service';

interface UpdateRequest { displayName: string; disabled: boolean; uid: string; password?: string; }

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {

  displayName = '';
  email = '';
  password = '';
  permission = 0;
  Permissions = PERMISSIONS;
  loadingState = LoadingState.IDLE;
  messages = {
    [LoadingState.WORKING]: 'Uppdaterar användare',
    [LoadingState.ERROR]: 'ERROR',
    [LoadingState.DONE]: 'Användare uppdaterad'
  };

  users: User[];
  selectedUser: User;

  constructor(private userService: UserService, private afAuth: AngularFireAuth) { }

  async ngOnInit() {
    firestore().collection('users').onSnapshot(usersSnapshot => {
      this.users = usersSnapshot.docs.map(userSnapshot => {
        const tempUser = Object.assign({}, userSnapshot.data(), { ref: userSnapshot.ref, id: userSnapshot.id });
        return new User(tempUser);
      }).sort((a, b) => a.displayName.toLocaleLowerCase().localeCompare(b.displayName.toLocaleLowerCase()));

    });
  }

  onUserChanged(event) {
    const user = event.value;
    this.displayName = user.displayName;
    this.email = user.email;
    this.permission = user.permission;
  }
  HasPermission(permission) {
    return HasPermission(this.permission, permission);
  }

  onPermissionChange(event, permission) {
    if (event.checked) {
      // tslint:disable-next-line:no-bitwise
      this.permission = this.permission | permission;
    } else {
      // tslint:disable-next-line:no-bitwise
      this.permission = this.permission &= ~permission;
    }
  }
  async submit() {

    this.loadingState = LoadingState.WORKING;
    const updateUser = functions().httpsCallable('updateUser');
    const updateRequest: UpdateRequest = {
      displayName: this.displayName,
      disabled: false,
      uid: this.selectedUser.id
    };

    if (this.password.length > 0 && this.password.length < 6) {
      this.loadingState = LoadingState.ERROR;
      this.messages[LoadingState.ERROR] = 'Lösenord måste innehålla minst 6 tecken';
      setTimeout(() => {
        this.loadingState = LoadingState.IDLE;
      }, 3000);
      return;
    }
    if (this.password.length >= 6) {
      updateRequest.password = this.password;
    }
    const result = await updateUser(updateRequest);
    console.log(result);
    if (result.data.success) {
      await this.selectedUser.ref.update({
        displayName: this.displayName,
        email: this.email,
        permission: this.permission
      });
      this.loadingState = LoadingState.DONE;
      setTimeout(() => {
        this.loadingState = LoadingState.IDLE;
        this.selectedUser = null;
        this.displayName = '';
        this.email = '';
        this.permission = 0;
      }, 2000);
    } else {
      let errorMessage = 'Error';
      switch (result.data.code) {
        case 'INSUFFICIENT_PERMISSIONS':
          errorMessage = 'Du saknar behörighet för denna åtgärd';
          break;
      }
      this.loadingState = LoadingState.ERROR;
      this.messages[LoadingState.ERROR] = errorMessage;
      setTimeout(() => {
        this.loadingState = LoadingState.IDLE;
      }, 3000);
    }

  }

}
