<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  "
>
  <div
    style="
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      style="
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <span>{{ progress }}%</span> <span>{{ amount }}</span>
    </div>
    <svg [attr.height]="radius * 2" [attr.width]="radius * 2">
      <circle
        stroke="#666666"
        fill="transparent"
        [attr.stroke-width]="stroke"
        [attr.r]="normalizedRadius"
        [attr.cx]="radius"
        [attr.cy]="radius"
      />
      <circle
        stroke="#36a2eb"
        fill="transparent"
        [attr.stroke-dasharray]="circumference + ' ' + circumference"
        [attr.stroke-dashoffset]="
          circumference - (progress / 100) * circumference
        "
        [attr.stroke-width]="stroke"
        [attr.r]="normalizedRadius"
        [attr.cx]="radius"
        [attr.cy]="radius"
      />
    </svg>
  </div>
  <span style="margin-top: 10px">{{ label }}</span>
</div>
