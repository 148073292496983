<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header-toolbar [sidenav]="sidenav" showBack="true">
    </app-header-toolbar>
    <div class="content-container" *ngIf="userService.User | async as User">
      <div *ngIf="todo$ | async as todo">
        <h1 style="display: flex; align-items: center">
          <span style="flex: 1">{{ todo.title }}</span>
          <div>
            <button
              [routerLink]="['/todo/edit', todo.id]"
              mat-icon-button
              *ngIf="User.HasPermission(Permissions.EDIT_TODO)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </h1>
        <p>Deadline: {{ todo.deadline }}</p>
        <mat-progress-bar
          style="height: 10px"
          mode="determinate"
          value="{{ getProgress(todo.doneAmount, todo.goalAmount) }}"
        ></mat-progress-bar>
      </div>
      <mat-card class="mat-elevation-z2 mb30">
        <table mat-table [dataSource]="todoItems$" style="width: 100%">
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef>SKU</th>
            <td
              mat-cell
              *matCellDef="let item"
              [routerLink]="
                User.HasPermission(Permissions.EDIT_TV)
                  ? ['/sku/edit', item.sku]
                  : []
              "
            >
              {{ item.sku }}
            </td>
            <td mat-footer-cell *matFooterCellDef>Totalt</td>
          </ng-container>
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modell</th>
            <td
              mat-cell
              *matCellDef="let item"
              (click)="addComment(item)"
              style="cursor: pointer"
            >
              <div style="display: flex; align-items: center">
                <span style="flex: 1"> {{ item.model }} </span>
                <span
                  *ngIf="item.comments && item.comments.length > 0"
                  style="margin-right: 10px"
                >
                  <mat-icon>comment</mat-icon>
                </span>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Antal</th>
            <td mat-cell *matCellDef="let item">
              {{ item.doneAmount }}/{{ item.goalAmount }}
              <small *ngIf="item.realSku | async as sku">
                <span *ngIf="sku.stock">
                  ({{ sku.stock.rtg }}/{{ sku.stock.available }})
                  <span
                    [ngClass]="{
                      error:
                        (sku.stock.rtg / sku.stock.available | Hitrate) <= 10,
                      warning:
                        (sku.stock.rtg / sku.stock.available | Hitrate) > 10 &&
                        (sku.stock.rtg / sku.stock.available | Hitrate) < 30,
                      success:
                        (sku.stock.rtg / sku.stock.available | Hitrate) >= 30
                    }"
                  >
                    {{ sku.stock.rtg / sku.stock.available | Hitrate }}%
                  </span>
                </span>
              </small>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <span *ngIf="total$ | async as total">
                {{ total.doneAmount }}/{{ total.goalAmount }}
                <small>
                  ( {{ total.goalAmount - total.doneAmount }} kvar )
                </small>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <span *ngIf="item.realSku | async as sku">
                <span *ngIf="sku.premium">P</span>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 1px"></th>
            <td mat-cell *matCellDef="let item">
              <div
                style="display: grid; grid-gap: 10px; grid-auto-flow: column"
              >
                <button
                  [disabled]="item.doneAmount === 0"
                  color="accent"
                  mat-mini-fab
                  (click)="sub(item)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
                <button color="accent" mat-mini-fab (click)="add(item)">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngStyle]="{
              'background-color':
                row.doneAmount >= row.goalAmount ? '#206040' : 'transparent'
            }"
          ></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
          ></tr>
        </table>
      </mat-card>
    </div> </mat-sidenav-content
></mat-sidenav-container>
