import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { LoadingState } from '../components/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  email: string;
  password: string;
  loadingState: LoadingState;
  messages: any;

  constructor(private db: AngularFirestore, private auth: AngularFireAuth, private route: ActivatedRoute) {
    this.returnUrl = '';
    this.loadingState = LoadingState.IDLE;
    this.messages = {
      [LoadingState.WORKING]: 'Loggar in',
      [LoadingState.ERROR]: 'ERROR',
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.returnUrl) {
        this.returnUrl = params.returnUrl;
      }
    });
  }

  async login() {

    this.loadingState = LoadingState.WORKING;
    try {
      const email = `${this.email}@giganten.se`;
      const userCred = await this.auth.auth.signInWithEmailAndPassword(email, this.password);
      if (userCred) {
        window.location.replace(`/${this.returnUrl}`);
      }
    } catch (error) {
      console.log('TCL: LoginComponent -> login -> error.code', error.code);
      console.log('TCL: LoginComponent -> login -> error.message', error.message);
      let message = '';
      let timeout = 2000;
      switch (error.code) {
        case 'auth/wrong-password':
          message = 'Lösenordet är ogiltigt';
          timeout = 1000;
          break;
        case 'auth/too-many-requests':
          message = 'Du har gjort för många misslyckade försök. Vänligen försök igen senare';
          break;
        case 'auth/invalid-email':
          message = 'E-postadressen är ogiltig';
          timeout = 1000;
          break;
        case 'auth/user-not-found':
          message = 'Konto med angiven e-postadress kunde inte hittas';
          break;
      }
      this.loadingState = LoadingState.ERROR;
      this.messages[LoadingState.ERROR] = message;
      setTimeout(() => {
        this.loadingState = LoadingState.IDLE;
      }, timeout);
    }

  }


  passwordKeyup(e) {
    if (e.key === 'Enter') {
      this.login();
    }
  }

}
