import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/User.service';

@Component({
  selector: 'app-todo-listing',
  templateUrl: './todo-listing.component.html',
  styleUrls: ['./todo-listing.component.scss']
})
export class TodoListingComponent implements OnInit {

  users = [];
  displayedColumns = ['title'];

  todos$: Observable<any[]>;

  constructor(private db: AngularFirestore, private router: Router, private auth: AngularFireAuth, public userService: UserService) { }

  async ngOnInit() {
    this.todos$ = this.db
      .collection('todo')
      .snapshotChanges()
      .pipe(map(actions => {
        const todosTemp = actions
          .map(action => {
            const data: any = action.payload.doc.data();
            const id = action.payload.doc.id;
            const ref = action.payload.doc.ref;
            const timestamp = action.payload.doc.get('deadline');
            const deadline = moment(timestamp.toDate()).format('YYYY-MM-DD');
            data.deadline = deadline;
            return Object.assign({}, data, { id, ref });
          })
          .sort((a, b) => a.deadline.localeCompare(b.deadline) * -1);
        const todosInprogress = todosTemp.filter(t => t.progress < 100);
        const todosDone = todosTemp.filter(t => t.progress >= 100);
        return [...todosInprogress, ...todosDone];
      }));

    this.todos$.subscribe(async (todos) => {
      this.users = await Promise.all(todos.map(async (todo) => {
        const userData = (await todo.createdBy.get()).data();
        return Object.assign({}, userData, { id: todo.createdBy.id, ref: todo.createdBy });
      }));
    });
  }

  createNewTodo() {
    this.router.navigateByUrl('/todo/new');
  }
  getProgress(doneAmount: number, goalAmount: number) {
    return (doneAmount / goalAmount) * 100;
  }
  getUser(createdBy) {
    return this.users.find(u => u.id === createdBy.id);
  }

}
