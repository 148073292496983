<div *ngIf="isVisible()" class="loading-container">
  <div class="loading-inner">
    <mat-card>
      <p style="text-align: center">{{ messages[loadingState] }}</p>
      <mat-progress-spinner
        *ngIf="loadingState == 1"
        mode="indeterminate"
      ></mat-progress-spinner>
      <span style="font-size: 44px">
        <mat-icon inline="true" color="warn" *ngIf="loadingState == 4"
          >error
        </mat-icon>
      </span>
    </mat-card>
  </div>
</div>
