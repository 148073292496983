<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <div class="content-container" *ngIf="userService.User | async as User">
      <mat-form-field appearance="fill">
        <mat-label>Namn</mat-label>
        <input matInput [(ngModel)]="displayName" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>E-postadress</mat-label>
        <input matInput disabled [(ngModel)]="User.email" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Nytt lösenord</mat-label>
        <input
          matInput
          type="password"
          [(ngModel)]="password"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Bekräfta nytt lösenord</mat-label>
        <input
          matInput
          type="password"
          [(ngModel)]="confPassword"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Nuvarande lösenord</mat-label>
        <input
          matInput
          type="password"
          [(ngModel)]="currentPassword"
          autocomplete="off"
        />
      </mat-form-field>
      <div style="display: flex; justify-content: flex-end">
        <button mat-raised-button color="primary" (click)="submit()">
          Spara ändringar
        </button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
