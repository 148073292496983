<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div class="sidenav-content">
      <div style="height: 60px"></div>
      <ul>
        <li><a [routerLink]="['/']">Hem</a></li>
        <li><a [routerLink]="['/todo']">RTG TV</a></li>
        <li><a [routerLink]="['/tv']">Hantera TV</a></li>
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mb30">
      <app-header-toolbar
        showBack="true"
        [sidenav]="sidenav"
      ></app-header-toolbar>
    </div>
    <div class="content-container">
      <mat-form-field appearance="fill">
        <mat-label>Sök efter Toner eller Skrivare...</mat-label>
        <input autocomplete="off" [formControl]="term" matInput type="text" />
      </mat-form-field>
      <mat-card class="mat-elevation-z2 mb30">
        <table mat-table [dataSource]="filteredToners$" style="width: 100%">
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Beskrivning</th>
            <td mat-cell *matCellDef="let item">{{ item.description }}</td>
          </ng-container>
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>Färg</th>
            <td mat-cell *matCellDef="let item">
              {{ tonerTranslation[item.color] }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 60px; text-align: center"
            >
              Antal
            </th>
            <td
              mat-cell
              *matCellDef="let item"
              style="width: 60px; text-align: center"
            >
              {{ item.amount }}
            </td>
          </ng-container>
          <ng-container matColumnDef="minimum">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 60px; text-align: center"
            >
              Minimum
            </th>
            <td
              mat-cell
              *matCellDef="let item"
              style="width: 60px; text-align: center"
            >
              {{ item.minimum }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ordered">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 60px; text-align: center"
            >
              Best.
            </th>
            <td
              mat-cell
              *matCellDef="let item"
              style="width: 60px; text-align: center"
            >
              {{ item.ordered }}
            </td>
          </ng-container>
          <ng-container matColumnDef="printers">
            <th mat-header-cell *matHeaderCellDef>Skrivare</th>
            <td mat-cell *matCellDef="let item">
              <p *ngFor="let printer of item.printers">{{ printer }}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="editToner(row)"
            style="cursor: pointer"
            [ngStyle]="{
              'background-color':
                row.amount < row.minimum ? '#f44336' : 'initial'
            }"
          ></tr>
        </table>
      </mat-card>
      <div class="fab">
        <button mat-fab color="primary" (click)="addToner()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-sidenav-content></mat-sidenav-container
>
<div [hidden]="!tonerModalVisible">
  <div class="overlay-wrapper">
    <div class="overlay-content">
      <mat-card>
        <div style="padding: 20px">
          <mat-form-field appearance="fill">
            <mat-label>Beskrivning</mat-label>
            <input
              autocomplete="off"
              [(ngModel)]="description"
              matInput
              type="text"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Färg</mat-label>
            <mat-select [(value)]="color">
              <mat-option *ngFor="let _color of colors" [value]="_color">
                {{ tonerTranslation[_color] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div style="display: grid; grid-auto-flow: column; grid-gap: 20px">
            <mat-form-field appearance="fill">
              <mat-label>Antal</mat-label>
              <input
                autocomplete="off"
                [(ngModel)]="amount"
                matInput
                type="number"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Minimum</mat-label>
              <input
                autocomplete="off"
                [(ngModel)]="minimum"
                matInput
                type="number"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Best.</mat-label>
              <input
                autocomplete="off"
                [(ngModel)]="ordered"
                matInput
                type="number"
              />
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Skrivare (en per rad)</mat-label>
            <textarea matInput rows="5" [(ngModel)]="printers"></textarea>
          </mat-form-field>
          <div
            style="
              display: grid;
              grid-template-columns: 100px auto 100px 100px;
              grid-gap: 20px;
            "
          >
            <button
              mat-raised-button
              color="warn"
              [disabled]="!isEditing"
              (click)="deleteToner()"
            >
              Ta bort
            </button>
            <div></div>
            <button mat-flat-button color="accent" (click)="cancel()">
              Avbryt
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="isEditing ? saveToner() : createToner()"
            >
              {{ isEditing ? "Spara" : "Lägg till" }}
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
