import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PERMISSIONS } from '../models/Permissions';
import { UserService } from '../services/User.service';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  showBack: boolean;

  @Input()
  sidenav: any;

  Permissions: typeof PERMISSIONS;

  constructor(public userService: UserService, private location: Location) {
    this.title = 'Tillbaka';
    this.showBack = false;
    this.Permissions = PERMISSIONS;
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}
